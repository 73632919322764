<template>
  <div class="">
    <set-view-as-employee v-if="isAdmin" />
    <br />

    <div v-if="isPM">
      <button :class="'btn'" @click="addJob">Add Job</button>
      <br /><br />
    </div>

    <div class="tab-section white">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab == 'my' }]">
          <a href="#" @click=";(tab = 'my'), setTable()"> My Jobs/WOs </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'all' }]" v-if="isCaptain">
          <a href="#" @click=";(tab = 'all'), setTable()"> All Jobs/WOs </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'missing' }]" v-if="isCaptain">
          <a
            href="#"
            @click="
              tab = 'missing'
              filterMissingPO = 'yes'
              clearAllFilters('filterMissingPO')
              setTable()
            "
          >
            Show Missing PO's
          </a>
        </li>
      </ul>

      <div :class="['card', { Main: tab == 'my' }]">
        <div class="content">
          <div v-if="tab !== 'missing'" class="row report-filters">
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Type</label>
              <select-field
                :options="[
                  { name: 'Jobs', value: 'jobs' },
                  { name: 'WOs', value: 'wos' },
                  { name: 'Jobs & WOs', value: 'all' }
                ]"
                :option-display-keys="['name']"
                :option-val="'value'"
                v-model="filterType"
                :allow-clear="true"
                @selectItem="setTable()"
              />
            </div>
            <div v-if="filterType === 'jobs' && tab !== 'my'" class="col-sm-12 col-md-3 col-lg-2">
              <label>Jobs</label>
              <select-field
                v-model="filterJob"
                :options="filteredJobs"
                :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
                :option-val="'Job_Number'"
                :empty-option-text="'No items.'"
                @selectItem="(clearAllFilters('filterJob'), setTable())"
                :allow-clear="true"
              />
            </div>
            <div v-if="filterType === 'wos' && tab !== 'my'" class="col-sm-12 col-md-3 col-lg-2">
              <label>WOs</label>
              <select-field
                v-model="filterWO"
                :options="filteredWOs"
                :option-display-keys="['WO_Number', 'Name', 'Summary_Description']"
                :option-val="'WO_Number'"
                :empty-option-text="'No items.'"
                @selectItem="(clearAllFilters('filterWO'), setTable())"
                :allow-clear="true"
              />
            </div>
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Customer</label>
              <select-field
                :options="customerList"
                :option-display-keys="['Name']"
                :option-val="'Customer_Code'"
                @selectItem="(clearAllFilters('filterCustomer'), setTable())"
                v-model="filterCustomer"
                :allow-clear="true"
              />
            </div>
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Project Lead</label>
              <select-field-multi
                :options="employeesWithRoleFlags"
                :option-display-keys="['Employee_Name']"
                :option-val="'Employee_Code'"
                v-model="filterPM"
                :filter-on-key-val="{ key: 'hasPMRole', val: 'yes' }"
                :allow-clear="true"
                @selectItem="setTable()"
                :show-remove-warning="false"
              />
            </div>
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Captain</label>
              <select-field-multi
                :options="employeesWithRoleFlags"
                :option-display-keys="['Employee_Name']"
                :option-val="'Employee_Code'"
                v-model="filterCaptain"
                :filter-on-key-val="{ key: 'hasCaptainRole', val: 'yes' }"
                :allow-clear="true"
                @selectItem="setTable()"
                :show-remove-warning="false"
              />
            </div>
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Stage</label>
              <select-field
                :options="[...projectStages, ...signOffStages]"
                :label-text="'Stage'"
                :option-display-keys="['description']"
                :option-val="'id'"
                v-model="filterStage"
                :allow-clear="true"
                @selectItem="setTable()"
              />
            </div>
          </div>

          <div v-if="tab !== 'missing'" class="row report-filters">
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Division</label>
              <select-field :options="divisionCostGroupOptions" :option-display-keys="['name']" :option-val="'value'" v-model="filterDivision" :allow-clear="true" @selectItem="setTable()" />
            </div>

            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Missing PO</label>
              <select-field
                :options="[
                  { name: 'Yes', value: 'yes' },
                  { name: 'No', value: 'no' }
                ]"
                :option-display-keys="['name']"
                :option-val="'value'"
                v-model="filterMissingPO"
                :allow-clear="false"
                @selectItem="setTable()"
              />
            </div>

            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Sort</label>
              <select-field
                :options="[
                  { name: 'Default', value: '' },
                  { name: 'Project Lead', value: 'pm_name' },
                  { name: 'Captain', value: 'captain_name' },
                  { name: 'Sales Person', value: 'sales_person_name' },
                  { name: 'Region', value: 'region' },
                  { name: 'Stage', value: 'stage' },
                  { name: 'Cost Center', value: 'cost_center' },
                  { name: 'Department', value: 'department' },
                  { name: 'Last Status Note Date', value: 'last_status_note_date' },
                  { name: 'Under/Over Billing', value: 'over_under_billing' }
                ]"
                :option-display-keys="['name']"
                :option-val="'value'"
                v-model="sortOption"
                :allow-clear="false"
                @selectItem="setTable()"
              />
            </div>

            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Sort Order</label>
              <select-field
                :options="[
                  { name: 'ASC', value: 'asc' },
                  { name: 'DESC', value: 'desc' }
                ]"
                :option-display-keys="['name']"
                :option-val="'value'"
                v-model="sortOrder"
                :allow-clear="false"
                @selectItem="setTable()"
              />
            </div>

            <div class="col-sm-12 col-md-3 col-lg-2">
              <button style="margin-top: 27px" class="btn" @click="download">Download</button>
            </div>
          </div>

          <div class="row" v-if="tab !== 'missing' && tooManyResults && !tableData.length">
            <div class="col-sm-12">
              <p class="text-danger" style="padding: 20px 0">
                <strong>Too many results, filter above or <span class="is-link underlined" @click="setTable('loadAll')">load anyway.</span></strong>
              </p>
            </div>
          </div>

          <br />

          <!-- <button class="btn btn-sm" @click="downloadCSV">Download CSV</button> -->

          <tabulator-table
            ref="reportTable"
            :table-data="tableData"
            :table-columns="tableColumns"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            :data-tree="false"
            :row-formatter="rowFormatter"
            :download-data-formatter="downloadDataFormatter"
            @colClick="colClick"
            Xmax-table-height="500px"
            table-height="700px"
          />
        </div>
      </div>
    </div>

    <waiting-spinner />
  </div>
</template>
<script>
import store from 'store'
import navTabs from 'mixins/navTabs'
import appFuncs from 'appFuncs'

import dateFormat from 'dateformat'
import { mapGetters } from 'vuex'
import JobFuncs from 'mixins/JobFuncs'
import WOFuncs from 'mixins/WOFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox'
import SelectField from 'components/UIComponents/SelectField'
import SelectFieldMulti from 'components/UIComponents/SelectFieldMulti'
import SetViewAsEmployee from 'components/Dashboard/Widgets/SetViewAsEmployee'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    var self = this
    return {
      tab: 'my',
      eid: store.getters.userAuthedData.eid,
      jobData: [],
      jobCosting: [],
      jobBilling: [],
      jobOpenShow: true,
      jobFinishedShow: true,
      jobHoldShow: true,
      jobCompleteShow: false,
      jobAllShow: false,
      jobMissingPoShow: false,
      costCenter: '',
      stageCode: '',
      noteStatusFilter: '',
      region: null,
      data: [],
      filterType: 'all',
      filterJob: '',
      filterWO: '',
      filterCustomer: '',
      filterCaptain: [],
      filterPM: [],
      filterRegion: '',
      filterStage: '',
      filterDivision: '',
      filterMissingPO: 'no',
      sortOption: '',
      sortOrder: 'desc',
      reportAuthCode: '',
      tooManyResults: false,
      initialFilters: {
        filterJob: '',
        filterWO: '',
        filterCustomer: '',
        filterCaptain: [],
        filterPM: [],
        filterRegion: null,
        filterStage: '',
        filterDivision: '',
        filterMissingPO: 'no'
      },
      woList: [],
      jobList: []
    }
  },

  props: {
    filterAll: {
      type: Boolean,
      default: false
    },
    stage: {
      type: [Number, String],
      default: 0
    }
  },

  components: {
    Checkbox2,
    SelectField,
    SelectFieldMulti,
    SetViewAsEmployee,
    TabulatorTable,
    WaitingSpinner
  },

  mixins: [navTabs, JobFuncs, WOFuncs],

  computed: {
    ...mapGetters(['userAuthedData', 'employees', 'regions', 'urls', 'customers', 'emCostGroups']),

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isCaptain() {
      return this.isAdmin || this.userAuthedData.employee_role === 'captain'
    },

    isPMp() {
      return this.isPM && this.userAuthedData.user_role.indexOf('P') !== -1
    },

    customerList() {
      return this.customers.map(itm => {
        return {
          Name: itm.Name,
          Customer_Code: itm.Customer_Code
        }
      })
    },

    tableColumns() {
      return [
        {
          field: 'id',
          visible: false
        },
        {
          field: 'type',
          visible: false
        },
        {
          field: 'itemNumber',
          visible: false
        },
        {
          title: 'Job/WO Number / Description / PO / Status Note',
          field: 'title',
          width: '350',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: 'Job Status',
          field: 'status',
          width: '150',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: '',
          field: 'status2',
          width: '150',
          formatter: 'html',
          headerSort: false
        },
        {
          title: 'Project Dates',
          field: 'project_dates',
          width: '120',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: '',
          field: 'project_dates2',
          width: '120',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: 'Customer / Location / Contact',
          field: 'customer',
          width: '250',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: 'Costing',
          field: 'costing',
          width: '150',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: '',
          field: 'costing2',
          width: '150',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: 'Cost Dates',
          field: 'dates',
          width: '150',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: '',
          field: 'dates2',
          width: '150',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: 'Division / Region / Project Lead / Captain',
          field: 'meta',
          width: '200',
          headerSort: false,
          formatter: 'html'
        }
      ]
    },

    employeesWithRoleFlags() {
      return this.employees
        .filter(itm => itm.Employment_Status === 'A')
        .map(itm => {
          if (Array.isArray(itm.job_roles)) {
            itm.hasPMRole = itm.job_roles.includes('pm') ? 'yes' : 'no'
            itm.hasCaptainRole = itm.job_roles.includes('captain') ? 'yes' : 'no'

            // // TEMP add captain role to all employees if not set
            // if (!itm.job_roles.length) {
            //   itm.hasCaptainRole = 'yes'
            //   itm.hasPMRole = 'yes'
            // }
          }
          return itm
        })
    },

    tableData() {
      let data = this.data
      let myItems = []
      let filtered = []

      filtered = JSON.parse(JSON.stringify(data))

      // filtered = filtered.filter(itm => {
      //   if (this.filterType === 'Jobs') {
      //     return itm.job_number
      //   } else if (this.filterType === 'WOs') {
      //     return itm.wo_number
      //   } else {
      //     return true
      //   }
      // })

      // filtered = filtered.filter(itm => {
      //   const costCenterMatch = this.costCenter ? itm.cost_center == this.costCenter : true
      //   const pmMatch = this.filterPM ? itm.pm == this.filterPM : true
      //   const captainMatch = this.filterCaptain ? itm.captain == this.filterCaptain : true
      //   const regionMatch = this.filterRegion ? itm.region == this.filterRegion : true
      //   const stageMatch = this.filterStage ? itm.stage == this.filterStage : true
      //   return costCenterMatch && pmMatch && captainMatch && regionMatch && stageMatch
      // })

      filtered = filtered.flatMap(itm => {
        const costCenterNumber = itm.cost_center
        const costCenter = this.costCenters.find(cc => cc.value == costCenterNumber)
        const costCenterName = costCenter ? costCenter.name : ''

        const region = this.regions.find(r => r.id == itm.region)
        const regionName = region ? region.name : ''

        const pm = this.employees.find(e => e.Employee_Code == itm.pm)
        const pmName = pm ? pm.Employee_Name : ''

        const captain = this.employees.find(e => e.Employee_Code == itm.captain)
        const captainName = captain ? captain.Employee_Name : ''

        const salesPerson = this.employees.find(e => e.Employee_Code == itm.sales_person)
        const salesPersonName = salesPerson ? salesPerson.Employee_Name : ''

        let stageVal = itm.stage ? itm.stage : 'Add'
        if (stageVal === 'Report Sent' || stageVal === 'WO Sent') {
          stageVal = `Sent`
          if (itm.last_report_send_date) {
            stageVal = ` <span class="text-danger">Sent (${itm.last_report_send_date})</span>`
          }
        }

        const projectStage = itm.project_stage ? this.getProjectStage(itm.project_stage)?.description : stageVal
        const signOffStage = itm.sign_off_stage ? this.getSignOffStage(itm.sign_off_stage)?.description : itm.sign_off === 'yes' ? 'Signed' : 'Not Signed'

        return [
          {
            id: this.randomCharacterString(12),
            job_or_wo: itm.job_number ? 'Job' : 'WO',
            itemNumber: itm.job_number || itm.wo_number,
            title: itm.job_number ? `Job ${itm.job_number}` : `WO ${itm.wo_number}`,
            project_dates: 'Start Date',
            project_dates2: this.formatDate(itm.project_start_date),
            status: 'Stage:',
            status2: projectStage,
            customer: itm.customer_name,
            meta: costCenterName,
            costing: 'Quoted Cost:',
            costing2: this.formatNumber(itm.quoted_cost, 1),
            dates: 'Date Ordered:',
            dates2: this.formatDate(itm.date_ordered),
            class: 'first-row',
            stageName: itm.project_stage || 'no-stage'
          },
          {
            id: this.randomCharacterString(12),
            job_or_wo: itm.job_number ? 'Job' : 'WO',
            itemNumber: itm.job_number || itm.wo_number,
            title: itm.description,
            project_dates: 'Finish Date',
            project_dates2: this.formatDate(itm.project_finish_date),
            status: 'Sign Off Status:',
            status2: signOffStage,
            customer: `<a target="_blank" href="https://maps.google.com?q=${encodeURIComponent(itm.address)}">${itm.address}</a>`,
            meta: regionName,
            costing: 'Cost to Date:',
            costing2: this.formatNumber(itm.cost_to_date, 1),
            dates: 'Last Work Date:',
            dates2: this.formatDate(itm.last_cost_date),
            class: 'second-row',
            stageName: itm.project_stage || 'no-stage'
          },
          {
            id: this.randomCharacterString(12),
            job_or_wo: itm.job_number ? 'Job' : 'WO',
            itemNumber: itm.job_number || itm.wo_number,
            title: itm.po_number ? `<span class="text-danger underlined is-link">PO: ${itm.po_number}</span>` : '<span class="text-danger underlined is-link">PO: Add</span>',
            project_dates: 'Hours Quoted',
            project_dates2: itm.job_number ? this.formatNumber(itm.project_hours_quoted) : 'n/a',
            status: `<span class="underlined is-link">Billing Tasks</span>`,
            status2: `<span class="underlined is-link">Create</span>`,
            customer: itm.contact_name,
            meta: pmName,
            costing: 'Billed to Date:',
            costing2: this.formatNumber(itm.billed_to_date, 1),
            dates: 'Last Billed Date:',
            dates2: itm.last_billed_date ? this.formatDate(itm.last_billed_date) : 'Not Billed',
            class: 'third-row',
            stageName: itm.project_stage || 'no-stage'
          },
          {
            id: this.randomCharacterString(12),
            job_or_wo: itm.job_number ? 'Job' : 'WO',
            itemNumber: itm.job_number || itm.wo_number,
            title: itm.last_status_note_text ? `<span style="font-style:italic">${itm.last_status_note_text}</span>` : '<span class="text-danger underlined">None</span>',
            project_dates: 'JTD Hours',
            project_dates2: this.formatNumber(itm.project_jtd_hours),
            status: 'Last Status Note:',
            status2: itm.last_status_note_date,
            customer: '',
            meta: captainName,
            costing: 'Under/Over Billing:',
            costing2: itm.over_under_billing,
            dates: 'Net Cash Flow',
            dates2: this.formatNumber(itm.net_cash_flow, 1),
            class: 'fourth-row',
            stageName: itm.project_stage || 'no-stage'
          },
          {
            id: this.randomCharacterString(12),
            job_or_wo: itm.job_number ? 'Job' : 'WO',
            itemNumber: itm.job_number || itm.wo_number,
            title: '',
            project_dates: 'Hours Left',
            project_dates2: itm.job_number ? this.formatNumber(itm.project_hours_left) : 'n/a',
            status: '',
            status2: '',
            customer: '',
            meta: '',
            costing: 'Contract Amount',
            costing2: itm.job_number ? this.formatNumber(itm.contract_amount) : 'n/a',
            dates: '% Complete',
            dates2: itm.job_number ? `${this.formatNumber(itm.percent_complete)} %` : 'n/a',
            class: 'fifth-row',
            stageName: itm.project_stage || 'no-stage'
          }
        ]
      })

      return filtered
    },

    filteredJobs() {
      let jobs = this.jobList.slice() // clone the input array
      jobs.sort((a, b) => b.Job_Number - a.Job_Number)
      return jobs
    },

    filteredWOs() {
      let wos = this.woList.slice() // clone the input array
      wos.sort((a, b) => b.WO_Number - a.WO_Number)
      return wos
    }
  },

  methods: {
    setTable(loadAll) {
      this.getJobWOBillingReportData(loadAll)
    },

    downloadCSV() {
      const table = this.$refs.reportTable
      if (table) {
        table.exportCSV('JobWOReport')
      }
    },

    rowFormatter(row) {
      var data = row.getData()
      let rowEl = row.getElement()
      // const firstRow = data.class === 'first'

      let updateRow = false
      let rowEntry = null

      // const rowIndex = row.getPosition()
      // console.log(rowIndex)
      // if (rowIndex == 0) {
      //   row.freeze()
      // }

      // const parentRow = data._children && data._children.length ? data : null
      // if (firstRow) {

      row.getElement().classList.add(data.class, data.stageName)

      if (data.class === 'first-row') {
        row.getCell('title').setValue(`<span class="is-link text-blue underlined"><strong>${data.title}</strong></span>`)
        row.getCell('status2').setValue(`<span class="is-link underlined">${data.status2}</span>`)
        row.getCell('project_dates2').setValue(`<span class="is-link underlined">${data.project_dates2}</span>`)
      }

      if (data.class === 'second-row') {
        row.getCell('status2').setValue(`<span class="is-link underlined">${data.status2}</span>`)
        row.getCell('project_dates2').setValue(`<span class="is-link underlined">${data.project_dates2}</span>`)
      }

      if (data.class === 'fourth-row') {
        // row.getCell('title').setValue(`<span class="is-link underlined">${data.title}</strong>`)
        // row.getCell('title').getElement().cssClass('is-link')
      }

      if (data.class === 'fourth-row') {
        // "so the formula should be that POSITVE underbilling is showing as RED and negative is GREEN. that is how it is for jobs correct? " https://teams.microsoft.com/l/message/19:23bb520e-7da3-4db7-a1a0-a44c0100d018_f182ec0e-e4b4-4b92-944f-7af4dd328618@unq.gbl.spaces/1717674845551?context=%7B%22contextType%22%3A%22chat%22%7D
        // if (parseFloat(data.costing2) > 0) {
        //   row.getCell('costing2').setValue(`<span class="text-danger">(${this.formatNumber(data.costing2, 1)})</strong>`)
        // } else {
        //   row.getCell('costing2').setValue(`<span class="text-success">(${this.formatNumber(data.costing2, 1)})</strong>`)
        // }

        let lastStatusNoteDateVal = '',
          noteText = ''
        const statusNotesClass = this.getLastStatusNoteClass(data.status2)
        if (data.status2) {
          lastStatusNoteDateVal = `<span class="${statusNotesClass}">${data.status2 ? this.formatDate(data.status2) : '<span class="underline text-danger">None</span>'}</span>`
          noteText = data.title ? `<span class=" ${statusNotesClass}">${data.title}</span>` : `<span class="underline text-danger">None</span>`
        } else {
          lastStatusNoteDateVal = '<span class="underline text-danger">None</span>'
          noteText = data.title ? data.title : '<span class="underline text-danger">None</span>'
        }
        row.getCell('status2').setValue(lastStatusNoteDateVal)
        row.getCell('title').setValue(noteText)
      }
    },

    download() {
      // window.open(this.urls.frontend + '#/job-wo-report-template/' + this.reportAuthCode)

      // return

      return new Promise((resolve, reject) => {
        if (!this.tableData.length) {
          this.$snack.open('No data to download', 'warning')
          resolve()
          return
        }
        if (this.tableData.length > 50 * 4) {
          // each row is 4 lines
          this.$snack.open('Results must be filtered to less than 50 to download', 'warning')
          resolve()
          return
        }

        if (!this.reportAuthCode) {
          this.$snack.open('Problem with download link auth', 'error')
          resolve()
          return
        }
        const params = {
          action: 'getJobWOReportPDF',
          auth_code: this.reportAuthCode
        }

        this.$bus.$emit('setWaiting', { name: 'gettingReport', message: 'Downloading Report' })
        appFuncs
          .shHttp(params)
          .then(res => {
            if (res && res.location) {
              window.open(res.location)
              resolve()
            } else {
              this.$snack.open('Problem with download link', 'error')
              resolve()
            }
            resolve()
          })
          .catch(res => {
            console.log(res)
            this.$snack.open(res.message || 'Problem downloading report', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'gettingReport')
          })
      })
    },

    getLastStatusNoteClass(val) {
      const currentDate = new Date()
      const lastStatusNoteDate = val ? (!isNaN(val) ? (val.toString().length === 10 ? new Date(val * 1000) : new Date(val)) : new Date(val)) : null
      const statusNotesDaysOld = lastStatusNoteDate ? Math.floor((currentDate - lastStatusNoteDate) / (1000 * 60 * 60 * 24)) : null
      let statusNotesClass = ''
      if (statusNotesDaysOld !== null) {
        if (statusNotesDaysOld <= 14) {
          // statusNotesClass = 'text-success'
        } else if (statusNotesDaysOld <= 28) {
          // statusNotesClass = 'text-warning'
        } else {
          statusNotesClass = 'text-danger'
        }
      }
      return statusNotesClass
    },

    colClick(obj) {
      const rowNum = (obj.row || {}).class
      const col = (obj.cell || {}).field

      const itemNumber = (obj.row || {}).itemNumber
      const type = (obj.row || {}).job_or_wo
      const poNumber = (obj.row || {}).po_number

      let job = {}
      let wo = {}

      if (type === 'Job') {
        job = this.data.find(job => job.job_number == itemNumber)
      } else if (type === 'WO') {
        wo = this.data.find(wo => wo.wo_number == itemNumber)
      }

      if (rowNum === 'first-row' && col === 'title') {
        const id = itemNumber
        const type = obj.row.job_or_wo
        if (type === 'Job') {
          this.$router.push('/app/job-details/' + id)
        } else if (type === 'WO') {
          this.$router.push('/app/wo-details/' + id)
        }
      }

      if ((rowNum === 'first-row' || rowNum === 'second-row') && col === 'status2') {
        const id = itemNumber
        const type = obj.row.job_or_wo
        if (type === 'Job') {
          this.editJobDates(itemNumber)
          // this.openJobWipMain({
          //   Job_Number: itemNumber,
          //   ...(this.data.find(job => job.job_number == itemNumber) || {})
          // })
        } else {
          this.editWODates(itemNumber)
          // this.openWOWipMain({
          //   WO_Number: itemNumber,
          //   ...(this.data.find(wo => wo.wo_number == itemNumber) || {})
          // })
        }
      }

      if ((rowNum === 'first-row' || rowNum === 'second-row') && col === 'project_dates2') {
        const id = itemNumber
        const type = obj.row.job_or_wo

        if (type === 'Job') {
          this.editJobDates(itemNumber)
        } else {
          this.editWODates(itemNumber)
        }
      }

      if (rowNum === 'third-row') {
        if (col === 'title') {
          if (type === 'Job') {
            this.editPONumber({
              Job_Number: itemNumber,
              po_number: job.po_number
            })
          } else if (type === 'WO') {
            this.editPONumber({
              WO_Number: itemNumber,
              Customer_PO_Number: wo.po_number
            })
          }
        } else if (col === 'status') {
          if (this.enableBillingTasks) {
            this.viewBillingTasks({
              Job_Number: type === 'Job' ? itemNumber : null,
              WO_Number: type === 'WO' ? itemNumber : null
            })
          }
        } else if (col === 'status2') {
          if (this.enableBillingTasks) {
            this.openBillingTask({
              Job_Number: type === 'Job' ? itemNumber : null,
              WO_Number: type === 'WO' ? itemNumber : null
            })
          }
        }
      }

      if (rowNum === 'fourth-row' && col === 'title') {
        if (type === 'Job') {
          this.editJobDates(itemNumber)
          // this.openJobWipNotes({
          //   Job_Number: itemNumber
          // })
        } else {
          this.editWODates(itemNumber)
          // this.openWOWipNotes({
          //   WO_Number: itemNumber
          // })
        }
      }
    },

    editJobDates(itmNumber) {
      this.editDates(
        {
          Job_Number: itmNumber,
          ...(this.data.find(job => job.job_number == itmNumber) || {})
        },
        'Job'
      )
    },

    editWODates(itmNumber) {
      this.editDates(
        {
          WO_Number: itmNumber,
          ...(this.data.find(wo => wo.wo_number == itmNumber) || {})
        },
        'WO'
      )
    },

    editPONumber(obj) {
      this.$bus.$emit('modalOpen', {
        title: 'Edit PO Number',
        classes: 'gray-bg',
        name: 'JobWOPOEdit',
        title: '',
        size: 'modal-md',
        component: () => import('src/components/Dashboard/JobWo/JobWoPoEdit.vue'),
        componentData: {
          obj
        }
      })
    },

    listenPOEdit(obj) {
      const tableRef = this.$refs.reportTable
      const po_number = obj.po
      console.log(obj)
      if (tableRef) {
        const jobOrWO = obj.WO_Number ? 'WO' : 'Job'
        const itemNumber = obj.WO_Number || obj.Job_Number
        const rowIndex = tableRef.table.getData().findIndex(row => {
          const rowMatch = row.itemNumber == itemNumber
          const rowNumMatch = row.class == 'third-row'
          const rowTypeMatch = row.job_or_wo == jobOrWO
          return rowMatch && rowNumMatch && rowTypeMatch
        })

        if (rowIndex !== -1) {
          const id = tableRef.table.getData()[rowIndex].id
          tableRef.table.updateData([{ id, title: `PO: ${po_number}` }])
        }
      }
    },

    listenStageEdit(obj) {
      const tableRef = this.$refs.reportTable

      let sign_off = obj.sign_off
      if (sign_off === 'yes') {
        sign_off = 'Signed'
      } else {
        sign_off = 'Not Signed'
      }

      let stageName = ''
      if (obj.job_number) {
        stageName = obj.project_stage ? this.getProjectStage(obj.project_stage)?.description : null
        sign_off = obj.sign_off_stage ? this.getSignOffStage(obj.sign_off_stage)?.description : null
        if (!stageName) {
          const stage = this.jobStages.find(stage => stage.code == obj.status)
          stageName = stage ? stage.description : obj.status
        }
      } else {
        stageName = obj.project_stage ? this.getProjectStage(obj.project_stage)?.description : null
        sign_off = obj.sign_off_stage ? this.getSignOffStage(obj.sign_off_stage)?.description : null
        if (!stageName) {
          const stage = this.woPriorityDescriptions.find(stage => stage.code == obj.priority_code)
          stageName = stage ? stage.description : obj.priority_code
        }
      }

      if (tableRef) {
        const jobOrWO = obj.wo_number ? 'WO' : 'Job'
        const itemNumber = obj.wo_number || obj.job_number
        const rowIndexFirst = tableRef.table.getData().findIndex(row => {
          const rowMatch = row.itemNumber == itemNumber
          const rowNumMatch = row.class == 'first-row'
          const rowTypeMatch = row.job_or_wo == jobOrWO
          return rowMatch && rowNumMatch && rowTypeMatch
        })

        if (rowIndexFirst !== -1) {
          const id = tableRef.table.getData()[rowIndexFirst].id
          tableRef.table.updateData([{ id, status2: stageName }])
        }

        const rowIndexSecond = tableRef.table.getData().findIndex(row => {
          const rowMatch = row.itemNumber == itemNumber
          const rowNumMatch = row.class == 'second-row'
          const rowTypeMatch = row.job_or_wo == jobOrWO
          return rowMatch && rowNumMatch && rowTypeMatch
        })

        if (rowIndexSecond !== -1) {
          const id = tableRef.table.getData()[rowIndexSecond].id
          tableRef.table.updateData([{ id, status2: sign_off }])
        }
      }
    },

    editRequiredDate(obj) {
      this.$Modal({
        parent: this,
        name: 'JobStageUpdate',
        size: 'sm',
        component: () => import('src/components/Dashboard/JobWo/JobStageUpdate.vue'),
        title: 'Project Status',
        props: {
          inputJobId: obj.Job_Number,
          jobData: obj //singleJobData,
        }
      })
    },

    openWOWipMain(obj) {
      this.$Modal({
        parent: this,
        name: 'WOStageUpdate', // used for closing specific modal programmatically
        size: 'sm', // sm, md, lg, xl
        title: `Project Status`,
        component: () => import('src/components/Dashboard/JobWo/WOStageUpdate.vue'),
        props: {
          woNumber: obj.WO_Number,
          wo: obj
        }
      })
    },

    openJobWipMain(obj) {
      this.$Modal({
        parent: this,
        name: 'JobStageUpdate',
        size: 'sm',
        component: () => import('src/components/Dashboard/JobWo/JobStageUpdate.vue'),
        title: 'Project Status',
        props: {
          inputJobId: obj.Job_Number,
          jobData: obj //singleJobData,
        }
      })
    },

    openJobWipNotes(obj) {
      this.$Modal({
        parent: this,
        name: 'JobStatusNotes',
        size: 'lg',
        title: `Job Status Notes`,
        component: () => import('src/components/Dashboard/JobWo/JobStatusNotes.vue'),
        props: {
          jobNumber: obj.Job_Number,
          showJobNumber: true,
          jobNumbers: this.tab == 'My Jobs' ? this.myJobIds : this.jobIds
        }
      })
    },

    openWOWipNotes(obj) {
      this.$Modal({
        parent: this,
        name: 'WOStatusNotes',
        size: 'lg',
        title: `Work Order Status Notes`,
        component: () => import('src/components/Dashboard/JobWo/WOStatusNotes.vue'),
        props: {
          woNumber: obj.WO_Number,
          showWONumber: true
        }
      })
    },

    openBillingTask(obj) {
      // if no billing tasks yet open add new
      this.$Modal({
        parent: this,
        name: 'BillingTaskAdd',
        size: 'md',
        title: `Add Billing Task`,
        component: () => import('src/components/Dashboard/Billing/BillingTaskAdd.vue'),
        props: {
          Job_Number: obj.Job_Number,
          WO_Number: obj.WO_Number
        }
      })
    },

    viewBillingTasks(obj) {
      this.$Modal({
        parent: this,
        name: 'BillingTasks', // used for closing specific modal programmatically
        size: 'xxl', // sm, md, lg, xl
        title: `Billing Tasks`,
        // contentClasses: 'smoke-bg',
        component: () => import('src/components/Dashboard/Billing/BillingTasks.vue'),
        props: {
          jobNumber: obj.Job_Number,
          woNumber: obj.WO_Number,
          showTitle: true
        }
      })
    },

    addJob() {
      this.$Modal({
        parent: this,
        name: 'JobDetails',
        size: 'xl',
        contentClasses: 'gray-bg',
        component: () => import('src/components/Dashboard/JobWo/JobDetails.vue'),
        title: 'Add Job',
        props: {}
      })
    },

    selectRegion(selected_region) {
      this.region = selected_region
    },

    clearAllFilters(not) {
      const filterList = ['filterJob', 'filterWO', 'filterCustomer', 'filterCaptain', 'filterPM', 'filterRegion', 'filterStage', 'filterDivision', 'filterMissingPO']
      filterList.forEach(filter => {
        if (filter !== not) {
          this[filter] = this.initialFilters[filter]
        }
      })
    },

    getJobWOBillingReportData(loadAll) {
      return new Promise((resolve, reject) => {
        // $filterPM = $filters['pm'] ?? null;
        // $filterTechs = $filters['techs'] ?? null;
        // $showAll = $filters['showAll'] ?? null;
        // $filterType = $filters['type'] ?? null;
        // $filterCaptain = $filters['captain'] ?? null;
        // $filterSalesPerson = $filters['salesPerson'] ?? null;
        // $filterMyItemsId = $filters['myItems'] ?? null;

        // add validation to ensure filters are applied
        if (this.tab !== 'my' && !loadAll) {
          if (
            this.tab !== 'my' &&
            !this.filterPM.length &&
            !this.filterCaptain.length &&
            !this.filterStage &&
            !this.filterDivision &&
            this.filterMissingPO === 'no' &&
            !this.filterWO &&
            !this.filterJob &&
            !this.filterCustomer
          ) {
            // this.$snack.open('More filtering required', 'warning')
            this.tooManyResults = true
            this.data = []
            return
          } else {
          }
        }
        const sort = []
        if (this.sortOption) {
          sort.push([this.sortOption, this.sortOrder])
        }

        this.tooManyResults = false

        const asEmployeeCode = this.isAdmin && this.viewAsEmployeeId ? this.viewAsEmployeeId : this.userAuthedData.eid

        const params = {
          action: 'fetch_format_job_wo_report_data',
          filters: {
            type: this.filterType === 'all' ? null : this.filterType,
            captain: this.filterCaptain,
            pm: this.filterPM,
            salesPerson: this.filterSalesPerson,
            showAll: false,
            myItems: this.tab === 'my' ? asEmployeeCode : null,
            region: this.filterRegion,
            stage: this.filterStage,
            costGroupCode: this.filterDivision,
            missingPO: this.filterMissingPO,
            wo: this.filterWO,
            job: this.filterJob,
            customer: this.filterCustomer
          },
          sort
        }

        this.$bus.$emit('setWaiting', { name: 'getdd', message: 'Fetching Data' })

        appFuncs
          .shHttp(params)
          .then(res => {
            if (res.status === 'success') {
              this.data = res.data
              this.reportAuthCode = res.reportAuthCode
              resolve()
            } else {
              this.$snack.open(res.message || 'Problem getting Job Costing', 'error')
              reject()
            }
          })
          .catch(res => {
            console.log(res)
            this.$snack.open(res.message || 'Problem getting Job Costing', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'getdd')
          })
      })
    },

    downloadDataFormatter(data) {
      let formattedData = []

      for (let i = 0; i < data.length; i++) {
        formattedData.push(data[i])

        if (data[i].class === 'fourth-row') {
          formattedData.push({
            id: '',
            job_or_wo: '',
            title: '',
            status: '',
            status2: '',
            customer: '',
            meta: '',
            costing: '',
            costing2: '',
            dates: '',
            dates2: '',
            class: ''
          })
        }
      }
      return formattedData
    },

    listenUpdateStatusNotes(obj) {
      const tableRef = this.$refs.reportTable
      if (!tableRef) return
      const noteTable = obj.noteTable
      const noteText = obj.noteText
      const timestamp = obj.timestamp

      if (noteTable === 'JobStatusNotes') {
        const rowIndex = tableRef.table.getData().findIndex(row => {
          const rowMatch = row.itemNumber == obj.itemId
          const rowNumMatch = row.class == 'fourth-row'
          const rowTypeMatch = row.job_or_wo == 'Job'
          return rowMatch && rowNumMatch && rowTypeMatch
        })

        if (rowIndex !== -1) {
          const id = tableRef.table.getData()[rowIndex].id
          tableRef.table.updateData([{ id, title: noteText, status2: timestamp }])
        }
      }

      if (noteTable === 'WOStatusNotes') {
        const rowIndex = tableRef.table.getData().findIndex(row => {
          const rowMatch = row.itemNumber == obj.itemId
          const rowNumMatch = row.class == 'fourth-row'
          const rowTypeMatch = row.job_or_wo == 'WO'
          return rowMatch && rowNumMatch && rowTypeMatch
        })

        if (rowIndex !== -1) {
          const id = tableRef.table.getData()[rowIndex].id
          tableRef.table.updateData([{ id, title: noteText, status2: timestamp }])
        }
      }
    },

    editDates(obj, type) {
      this.$Modal({
        parent: this,
        name: 'SetEstimatedStartDate',
        size: 'lg',
        title: 'Project Update',
        component: () => import('components/Dashboard/JobWo/SetEstimatedStartDate.vue'),
        props: {
          obj,
          type
        }
      })
    },

    getJobList() {
      return new Promise(async (resolve, reject) => {
        if (this.jobList.length) return

        const params = {
          action: 'getJobList',
          notStatus: 'C'
        }

        this.$bus.$emit('setWaiting', { name: 'getJobList', message: 'Getting Job List' })
        appFuncs
          .shRequest(params)
          .then(data => {
            this.jobList = data
            resolve()
          })
          .catch(err => {
            this.$snack.open(err || 'Problem getJobList entries', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'getJobList')
          })
      })
    },

    getWOList() {
      return new Promise(async (resolve, reject) => {
        if (this.woList.length) return

        const params = {
          action: 'getWOList',
          notStatus: 'C'
        }

        this.$bus.$emit('setWaiting', { name: 'getWOList', message: 'Getting WO List' })
        appFuncs
          .shRequest(params)
          .then(data => {
            this.woList = data
            resolve()
          })
          .catch(err => {
            this.$snack.open(err || 'Problem getWOList entries', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'getWOList')
          })
      })
    }
  },

  async mounted() {
    if (this.$route.path == '/app/jobs/new') {
      this.addJob()
      return
    }

    const setPM = this.$route.query.pm
    // if view as employee is set and the same as pm then set tab to my
    if (setPM) {
      this.tab = 'all'
      this.filterPM = setPM
    } else {
      this.tab = 'my'
    }

    const setDept = this.$route.query.dept
    if (setDept) {
      this.filterDivision = setDept
    }

    if (this.$route.query.show === 'jobs-missing-po') {
      this.filterMissingPO = 'yes'
      this.filterType = 'jobs'
    } else if (this.$route.query.show === 'wos-missing-po') {
      this.filterMissingPO = 'yes'
      this.filterType = 'wos'
    } else if (this.$route.query.show === 'stagnant') {
      this.filterStage = 'stagnant'
    } else if (this.$route.query.show === 'unresponsive-signoff') {
      this.filterStage = 'unresponsive'
    } else if (this.$route.query.show === 'sign-off-sent') {
      this.filterStage = 'sign-off-sent'
    }

    if (this.$route.query.dept) {
      this.filterDivision = this.$route.query.dept
    }

    const employees = this.$store.dispatch('getEmployees')
    const regions = this.$store.dispatch('getRegions')
    const costCenters = this.$store.dispatch('getJobCostCenters')
    const customers = this.$store.dispatch('getCustomers')
    const emCostGroups = this.$store.dispatch('getEmCostGroups')

    // // todo replace this with something to get the minimum job and wo data
    // const wos = this.$store.dispatch('getWoAssemble')
    // const jobs = this.$store.dispatch('getJobAssemble')

    this.getJobWOBillingReportData()

    this.$bus.$on('JobWOPOEdit', this.listenPOEdit)
    this.$bus.$on('JobStatusNotesUpdate', this.listenUpdateStatusNotes)
    this.$bus.$on('WOStatusNotesUpdate', this.listenUpdateStatusNotes)
    this.$bus.$on('updateJobStage', this.listenStageEdit)
    this.$bus.$on('updateWOStage', this.listenStageEdit)
    this.$bus.$on('updateJobWO', this.getJobWOBillingReportData)
  },

  watch: {
    tab: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === 'my') {
            this.filterWO = ''
            this.filterJob = ''
            this.getJobWOBillingReportData()
          } else {
            this.data = []
          }
        }
      }
    },

    viewAsEmployeeId: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getJobWOBillingReportData()
        }
      }
    },

    filterType: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === 'jobs') {
            this.getJobList()
          } else if (newVal === 'wos') {
            this.getWOList()
          }
        }
      },
      immediate: true
    }
  },

  beforeDestroy() {
    this.$bus.$off('JobWOPOEdit', this.listenPOEdit)
    this.$bus.$off('WOStatusNotesUpdate', this.listenUpdateStatusNotes)
    this.$bus.$off('JobStatusNotesUpdate', this.listenUpdateStatusNotes)
    this.$bus.$off('updateJobStage', this.listenStageEdit)
    this.$bus.$off('updateWOStage', this.listenStageEdit)
    this.$bus.$off('updateJobWO', this.getJobWOBillingReportData)
  }
}
</script>

<style lang="scss" scoped>
// @import "src/assets/sass/paper/_variables.scss";

/* Custom styles for larger screens */
.filter-row {
  font-size: 0; /* To remove whitespace between inline-block elements */
  margin-bottom: 10px;
}

.inline-block-row {
  display: inline-block;
  vertical-align: top;
  font-size: 16px; /* Reset font size */
  width: auto; /* Allow width to fit content */
  float: none; /* Override Bootstrap's float */
}

/* Bootstrap's styles will take over for smaller screens (col-sm-6) */
@media (max-width: 767px) {
  .inline-block-row {
    display: block;
    width: 100%;
  }
}

.report-filters {
  > div {
    margin-bottom: 5px;
  }
}
</style>

<style lang="scss">
// .tabulator-header {
//   position: -webkit-sticky; /* For Safari */
//   position: sticky;
//   top: 0;
//   z-index: 10; /* Ensure it is above other elements */
//   background: white; /* Optional: to ensure the header has a background */
// }

.tabulator-table {
  .tabulator-row {
    &.first-row {
      border-top: 2px solid #000;
      background: #eee;

      &.stagnant {
        background: #ffe9e9;
      }
    }

    &.second-row {
      background: #fefefe;
      &.stagnant {
        background: #ffd2d2;
      }
    }

    &.third-row {
      background: #eee;
      &.stagnant {
        background: #ffe9e9;
      }
    }

    &.fourth-row {
      background: #fefefe;
      &.stagnant {
        background: #ffd2d2;
      }
    }

    &.fifth-row {
      background: #eee;
      &.stagnant {
        background: #ffe9e9;
      }
    }
  }

  [tabulator-field='title'] {
  }
}
</style>
