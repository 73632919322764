<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <button class="btn" @click.exact="addItem()" @click.alt="addItem('quote2')">New Quote</button>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-7 pull-right">
        <div class="legend">
          <div @click="changeStatusFilter('On Track', 'Quote in Progress', 'Quote in Progress')"><span class="blue" />On Track</div>
          <div @click="changeStatusFilter('Approaching', 'Quote in Progress', 'Quote in Progress')"><span class="yellow" />Approaching</div>
          <div @click="changeStatusFilter('Overdue Required', 'Quote in Progress', 'Quote in Progress')"><span class="orange" />Overdue Required</div>
          <div @click="changeStatusFilter('Overdue Decision', '', 'Sent and Pending')"><span class="purple" />Overdue Decision</div>
          <div @click="changeStatusFilter('', '', 'All', 'Won')"><span class="green" />Won</div>
          <div @click="changeStatusFilter('', '', 'All', 'Lost')"><span class="red" />Lost</div>
        </div>
        <!-- <select-field
          :options="fiscalYears"
          v-model="fiscalYear"
          :option-display-keys="['name']"
          :option-val="'val'"
        /> -->
      </div>
    </div>

    <br />
    <!--
      <widget-my-quotes :fiscal-year="fiscalYear" />
      <widget-my-sales-history :fiscal-year="fiscalYear" />
    <br><br> -->

    <div class="tab-section white">
      <div class="">
        <ul class="nav nav-tabs">
          <li role="presentation" :class="[{ active: tab == 'Quote in Progress' }]">
            <a href="#" @click="changeTab('Quote in Progress', 'Quote in Progress')"> Quote in Progress </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Sent and Pending' }]">
            <a href="#" @click="changeTab('Sent and Pending', 'Sent and Pending')"> Sent and Pending</a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'All' }]">
            <a href="#" @click="changeTab('All')"> All Quotes </a>
          </li>

          <li role="presentation" :class="[{ active: tab == 'unassigned' }]">
            <a href="#" @click="changeTab('unassigned', 'Unassigned')"> Unassigned Quotes </a>
          </li>
        </ul>

        <div :class="['card', 'quote-filters', { Main: tab == 'Quote in Progress' }]">
          <div class="content">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Fiscal Year</label>
                <select-field :options="fiscalYears" v-model="fiscalYear" :option-display-keys="['name']" :option-val="'val'" :allow-clear="true" />
              </div>
              <div v-if="tab === 'All'" class="col-sm-12 col-md-6 col-lg-3">
                <label>Status</label>
                <select-field :options="quoteStatusOptions" v-model="status" :option-display-keys="['name']" :option-val="'name'" :allow-clear="true" />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Customer</label>
                <select-field :options="customers" :option-display-keys="['Name']" :option-val="'Customer_Code'" v-model="customer" :allow-clear="true" />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Due Status</label>
                <select-field
                  :options="[{ name: 'Approaching' }, { name: 'Overdue Required' }, { name: 'Overdue Decision' }, { name: 'On Track' }]"
                  v-model="showDueStatus"
                  :option-display-keys="['name']"
                  :option-val="'name'"
                  :allow-clear="true"
                  @selectItem="doSearch(false)"
                />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Lead Estimator</label>
                <select-field
                  :options="quoteEmployeeList"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="quotedBy"
                  :allow-clear="true"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Sales Lead</label>
                <select-field
                  :options="quoteEmployeeList"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="salesPerson"
                  :allow-clear="true"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Captain</label>
                <select-field
                  :options="quoteEmployeeList"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="captain"
                  :allow-clear="true"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  @selectItem="doSearch(false)"
                />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Division</label>
                <select-field :options="divisionCostGroupOptions" :option-display-keys="['name']" :option-val="'value'" v-model="cost_center" :allow-clear="true" />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Any</label>
                <select-field-multi
                  :options="quoteEmployeeList"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="employeeAny"
                  :allow-clear="true"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  :show-remove-warning="false"
                  @selectItem="doSearch(false)"
                />
              </div>
            </div>
            <br />
            <div class="search-input-group">
              <input type="text" :class="['form-control', 'search-all', { 'not-empty': searchKeyword }]" v-model="searchKeyword" placeholder="Search quotes..." @keyup.enter="() => doSearch(false)" />
              <div class="search-buttons">
                <button class="btn btn-sm btn-blue" @click="() => doSearch(false)">Search Filtered</button>
                <button class="btn btn-sm" @click="() => clearFilters()">Search All</button>
                <button class="btn btn-sm btn-danger" @click="() => clearFilters(true)">Reset</button>
              </div>
            </div>
            <bh-table :table-settings="tableSettings1" @Xedit-item-obj="editItem" @colClick="colClick" />
          </div>
        </div>
      </div>

      <waiting-spinner />
    </div>
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import { mapGetters, mapState } from 'vuex'
import quoteFuncs from 'mixins/quoteFuncs'
import BhTable from 'components/UIComponents/BhTable'
// import WidgetMyQuotes from 'components/Dashboard/Widgets/WidgetMyQuotes';
// import WidgetMySalesHistory from 'components/Dashboard/Widgets/WidgetMySalesHistory.vue';
import SelectField from 'components/UIComponents/SelectField'
import SelectFieldMulti from 'components/UIComponents/SelectFieldMulti'
import navTabs from 'mixins/navTabs'
import RegionFilter from 'components/Dashboard/RegionFilter'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import dateFormat from 'dateformat'
import debounce from 'lodash/debounce'

export default {
  name: 'QuoteList',
  components: {
    BhTable,
    //  WidgetMyQuotes,
    //  WidgetMySalesHistory,
    SelectField,
    SelectFieldMulti,
    RegionFilter,
    WaitingSpinner
  },

  mixins: [navTabs, quoteFuncs],

  data() {
    var self = this
    return {
      tab: 'Quote in Progress',
      urls: store.getters.urls,
      tableData: [],
      formVals: {
        quote: ''
      },
      quotes: [],
      fiscalYear: 'all',
      status: ['Quote in Progress', 'Re-Opened - Not Sent'],
      customer: '',
      quotedBy: '',
      salesPerson: '',
      captain: '',
      skipFetching: true,
      employeeAny: [],
      cost_center: '',
      showCancelled: true,
      dbTable: 'QuoteList',
      tableSettings1: { hideSearch: true },
      searchKeyword: '',
      showDueStatus: '',
      region: null,
      quoteTimeEntries: [],
      fields: {
        quote_number: {
          name: 'Quote Number'
        },
        po_number: {
          name: 'PO Number'
        },
        sent_date: {
          name: 'Date Sent'
        },
        decision_date: {
          name: 'Cust Decision Date',
          class: 'show-as-link'
        },
        required_date: {
          name: 'Quote Required Date',
          class: 'show-as-link'
        },
        description: {
          name: 'Description',
          class: 'wide-medium'
        },
        last_activity: {
          name: 'Last Activity',
          hidden: 1
        },
        customerName: {
          name: 'Customer'
        },
        projected_price: {
          name: 'Projected Price'
        },
        date_required_hours: {
          name: 'Required hours'
        },
        date_project_start: {
          name: 'Projected Project Start'
        },
        date_project_complete: {
          name: 'Projected Project Complete'
        },
        quotingHoursRemaining: {
          name: 'Quoting Hours Remaining'
        },
        // cost_center: {
        //   name: 'Department'
        // },
        cost_center_name: {
          name: 'Department'
        },
        deposit_percent: {
          name: 'Deposit Amount'
        },
        contact: {
          name: 'Contact'
        },
        quoted_by_name: {
          name: 'Lead Estimator'
        },
        sales_lead_name: {
          name: 'Sales Lead'
        },
        captain_name: {
          name: 'Captain'
        },
        quoted_by_id: {
          hidden: 1
        },
        display_status: {
          name: 'Status'
        },
        status: {
          hidden: 1
        },
        display_price: {
          name: 'Selling Price'
        },
        won_lost: {
          name: 'Won/Lost'
        },
        region_name: {
          name: 'Region'
        }
      }
    }
  },

  props: {
    openQuoteIncr: {
      type: String,
      default: ''
    },

    openTab: {
      type: String,
      default: ''
    },

    setEditMode: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters(['quoteData', 'customers', 'employees', 'customerContacts', 'regions', 'configuredCostCenters', 'viewAsEmployeeId', 'emCostGroups']),

    userID() {
      return this.$store.getters.userAuthedData.eid
    },

    fiscalYears() {
      return this.getQuoteYears()
    },

    quoteEmployeeList() {
      if (Array.isArray(this.employees) && this.employees.length) {
        let employees = JSON.parse(JSON.stringify(this.employees))
        employees.unshift({ Employee_Name: 'All', Employee_Code: '' })
        employees.unshift({ Employee_Name: 'Unassigned', Employee_Code: 'unassigned' })

        return employees
      }
      return this.employees
    },

    costCenterRegions() {
      let costCenters = Array.isArray(this.configuredCostCenters) ? this.configuredCostCenters : null
      if (!costCenters) return []
      return costCenters.map(itm => {
        return {
          name: itm.name,
          value: itm.id
        }
      })
    },

    regionCostCenters() {
      let costCenters = Array.isArray(this.configuredCostCenters) ? this.configuredCostCenters : []
      if (this.region) {
        // Find the selected region
        const selectedRegion = costCenters.find(itm => itm.id === this.region)
        return selectedRegion
          ? selectedRegion.cost_centers.map(cc => ({
              name: cc.name,
              value: cc.value
            }))
          : []
      }

      // Process all regions when no region is selected
      const costCenterOptions = costCenters.reduce((acc, region) => {
        return acc.concat(
          region.cost_centers.map(cc => ({
            name: `${region.name} - ${cc.name}`,
            value: cc.value
          }))
        )
      }, [])

      // add quoteDepartments
      costCenterOptions.push(
        ...this.quoteDepartments.map(dept => ({
          name: dept.name,
          value: dept.name
        }))
      )

      return costCenterOptions
    }
  },

  methods: {
    colClick(obj) {
      const col = obj.col
      const quoteStatus = obj.itm.status
      if (col === 'required_date' && quoteStatus === 'Quote in Progress') {
        this.editRequiredDate(obj.itm)
      } else if (col === 'projected_price' && quoteStatus === 'Quote in Progress') {
        this.editRequiredPrice(obj.itm)
      } else if (col === 'decision_date') {
        this.editDecisionDate(obj.itm)
      } else {
        this.editItem(obj.itm, obj.itm.quote_incr)
      }
    },

    changeStatusFilter(dueStatus, filterStatus, tab, statusFilter = '') {
      this.skipFetching = true
      this.showDueStatus = dueStatus
      if (filterStatus !== '') {
        this.status = filterStatus
      }
      if (tab || statusFilter) {
        this.changeTab(tab, statusFilter)
      }
      this.$nextTick(() => {
        this.skipFetching = false
      })
    },

    editRequiredDate(quoteObj) {
      this.$Modal({
        parent: this,
        name: 'SetRequiredDate', // used for closing specific modal programmatically
        size: 'sm', // sm, md, lg, xl
        title: 'Set Quote Required Date',
        component: () => import('components/Dashboard/Quotes/SetRequiredDate.vue'),
        props: {
          quoteObj
        }
      })
    },

    editRequiredPrice(quoteObj) {
      this.$Modal({
        parent: this,
        name: 'SetProjectedPrice', // used for closing specific modal programmatically
        size: 'sm', // sm, md, lg, xl
        title: 'Set Projected Price',
        component: () => import('components/Dashboard/Quotes/SetProjectedPrice.vue'),
        props: {
          quoteObj
        }
      })
    },

    editDecisionDate(quoteObj) {
      this.$Modal({
        parent: this,
        name: 'SetDecisionDate', // used for closing specific modal programmatically
        size: 'sm', // sm, md, lg, xl
        title: 'Set Customer Decision Date',
        component: () => import('components/Dashboard/Quotes/SetDecisionDate.vue'),
        props: {
          quoteObj
        }
      })
    },

    setTableData() {
      let fields = JSON.parse(JSON.stringify(this.fields))

      if (this.tab === 'Quote in Progress') {
        fields.display_status.hidden = true
        fields.sent_date.hidden = true
        fields.won_lost.hidden = true
        fields.display_price.hidden = true
        fields.decision_date.hidden = true
      } else if (this.tab === 'Sent and Pending') {
        fields.required_date.hidden = true
        fields.won_lost.hidden = true
        fields.display_status.hidden = true
        fields.quotingHoursRemaining.hidden = true
        fields.projected_price.hidden = true
        fields.date_required_hours.hidden = true // optionable
        fields.date_project_start.hidden = true
        fields.date_project_complete.hidden = true
      } else {
        fields.required_date.hidden = true
        fields.decision_date.hidden = true
        fields.quotingHoursRemaining.hidden = true
        fields.projected_price.hidden = true
        fields.date_required_hours = true // optionable
        fields.date_project_start.hidden = true
        fields.date_project_complete.hidden = true
      }

      let out = []
      let quotes = this.filterQuoteVersions(JSON.parse(JSON.stringify(this.quotes)))

      // add class to highlight item in table
      var appDateISO = store.getters.appDate
      let appDate = appDateISO.replace(/-/g, '')
      let appDatePlus2 = appDateISO

      if (appDateISO) {
        appDatePlus2 = new Date(appDateISO)
        appDatePlus2.setTime(appDatePlus2.getTime() + 2 * 24 * 60 * 60 * 1000)
        // Format the date back to 'YYYY-MM-DD'
        appDatePlus2 = appDatePlus2.toISOString().split('T')[0].replace(/-/g, '')
      }

      for (var i = quotes.length - 1; i >= 0; i--) {
        let last_activity = ''
        if (quotes[i].last_note && quotes[i].last_note.timestamp) {
          if (quotes[i].last_note.timestamp && quotes[i].last_note.timestamp.length === 10) {
            // is valid timestamp
            last_activity = dateFormat(new Date(quotes[i].last_note.timestamp * 1000), 'mmm dS, yyyy')
            quotes[i].last_activity = {
              action: 'quoteEditNotes',
              title: last_activity
            }
          }
        }

        const quoteTimeEntries = Array.isArray(this.quoteTimeEntries) ? this.quoteTimeEntries : []
        quotes[i].hoursQuotedTotal = quoteTimeEntries.find(entry => entry.quote_incr == quotes[i].quote_incr)?.hoursRemaining || 0

        const hoursRemaining = quotes[i].hours_required_to_quote ? parseFloat(quotes[i].hours_required_to_quote) - parseFloat(quotes[i].hoursQuotedTotal) : '--'
        quotes[i].date_required_hours = hoursRemaining
        quotes[i].quotingHoursRemaining = !isNaN(hoursRemaining) ? hoursRemaining : '--'

        quotes[i].display_price = appFuncs.formatNumber(quotes[i].selling_price, '$')

        quotes[i].projected_price = appFuncs.formatNumber(quotes[i].price, '$')

        quotes[i].display_status = quotes[i].status

        if (quotes[i].won_lost === 'Won') {
          quotes[i].display_status = quotes[i].won_lost
          quotes[i].class = 'green'
        } else if (quotes[i].won_lost === 'Lost') {
          quotes[i].display_status = quotes[i].won_lost
          quotes[i].class = 'red'
        }

        let customerMatch = this.customers.find(itm => {
          return itm.Customer_Code == quotes[i].customer_code
        })
        quotes[i].customerName = (customerMatch && customerMatch.Name) || ''

        if (quotes[i].captain === 'unassigned') {
          quotes[i].captain_name = 'Unassigned'
        }
        if (quotes[i].sales_lead_id === 'unassigned') {
          quotes[i].sales_lead_name = 'Unassigned'
        }
        if (quotes[i].quoted_by_id === 'unassigned') {
          quotes[i].quoted_by_name = 'Unassigned'
        }

        if (!quotes[i].sales_lead_id) {
          quotes[i].sales_lead_name = ''
        }
        if (!quotes[i].quoted_by_id) {
          quotes[i].quoted_by_name = ''
        }
        if (!quotes[i].captain) {
          quotes[i].captain_name = ''
        }

        let region_name = ''
        if (quotes[i].region != null && quotes[i].region != undefined && quotes[i].region != '') {
          for (const region of this.regions) {
            if (quotes[i].region == region.id) {
              region_name = region.name
            }
          }
        }
        quotes[i].region_name = region_name

        quotes[i].cost_center_name = this.getQuoteDept(quotes[i].cost_center, quotes[i].region) || quotes[i].job_type

        // Format PO number display - show "PO# {number}" if exists, empty string if not
        if (quotes[i].po_number && quotes[i].po_number.trim()) {
          quotes[i].po_number = `PO# ${quotes[i].po_number.trim()}`
        } else {
          quotes[i].po_number = ''
        }

        out.push(quotes[i])
      }

      // sort by quote_incr
      out.sort((a, b) => {
        return b.quote_incr - a.quote_incr
      })

      this.tableSettings1 = { ...this.tableSettings1, tableData: out, fields }
    },

    async changeTab(tab, statusFilter) {
      let oldTab = this.tab

      const routeQuoteVal = this.$route.params.openQuoteIncr || ''
      const captain = decodeURIComponent(this.$route.query.captain || '')
      const currentStatusFilter = this.status

      // return if no changes to prevent dealth loop
      if ((!tab || tab === oldTab) && statusFilter === currentStatusFilter) return

      this.tab = tab

      if (oldTab === 'unassigned') {
        this.quotedBy = ''
        this.employeeAny = []
      }

      if (this.tab == 'Sent and Pending') {
        let statuses = ['Sent and Pending']
        this.status = statuses
        this.captain = captain
        this.showCancelled = true
        this.$router.push({ path: `/app/all-quotes/${routeQuoteVal}` })
      }

      if (this.tab == 'Quote in Progress') {
        let statuses = ['Quote in Progress', 'Re-Opened - Not Sent']
        this.status = statuses
        this.captain = captain
        this.showCancelled = true

        // this.$router.push({ path: `/app/all-quotes/${routeQuoteVal}` })
      }

      if (this.tab == 'All') {
        this.status = ''
        this.captain = captain
        this.showCancelled = true
        this.$router.push({ path: '/app/all-quotes' })
        if (!this.employeeAny.length && !captain) {
          // dont let there be too many results on load
          this.employeeAny = [this.userID]
        }
      }

      if (this.tab == 'unassigned') {
        this.quotedBy = ''
        this.salesLead = ''
        this.captain = captain || 'unassigned'
        this.employeeAny = []
        this.status = []
        this.showCancelled = false
        this.customer = ''
        this.cost_center = ''
        this.$router.push({ path: `/app/quotes/unassigned/${routeQuoteVal}` })
      }

      if (statusFilter) {
        this.status = statusFilter
      }

      this.region = null
      if (this.$refs.regionFilter) {
        this.$refs.regionFilter.region = null
      }

      this.searchKeyword = ''
    },

    editItem(obj, quoteIncr) {
      let quoteData = this.quotes.find(itm => {
        if (quoteIncr) {
          return parseInt(itm.quote_incr) === parseInt(quoteIncr)
        } else {
          return parseInt(itm.id) === parseInt(obj.id)
        }
      })

      // update url
      if (quoteData && quoteData.quote_incr) {
        this.$router.push({
          params: { openQuoteIncr: quoteData.quote_incr.toString() }
        })
      }

      this.$Modal({
        parent: this,
        name: 'quote-edit', // used for closing specific modal programmatically
        size: 'xxl', // sm, md, lg, xl
        hideClose: true,
        title: '',
        warnOnEsc: true,
        component: () => import('components/Dashboard/Quotes/Quote2Edit.vue'),
        props: {
          componentData: {
            quoteData: quoteData
          },
          openTab: this.openTab,
          setEditMode: this.setEditMode
        }
      })
    },

    addItem(openQuote2) {
      // if (openQuote2) {
      this.$Modal({
        parent: this,
        name: 'quote-edit', // used for closing specific modal programmatically
        size: 'xxl', // sm, md, lg, xl
        hideClose: true,
        title: '',
        component: () => import('components/Dashboard/Quotes/Quote2Edit.vue'),
        props: {
          componentData: {
            data: {}
          },
          newQuote: true,
          priceType: openQuote2 ? 'quote' : 'manual'
        }
      })
      // } else {
      //   this.$bus.$emit('modalOpen', {
      //     component: 'quote-add',
      //     name: 'quote-add',
      //     size: 'modal-xl',
      //     title: 'Add Quote',
      //     classes: 'gray-bg',
      //   });
      // }
    },

    selectRegion(selected_region) {
      this.region = selected_region
    },

    getQuoteDept(costCenter, region) {
      let costCenters = Array.isArray(this.configuredCostCenters) ? this.configuredCostCenters : []
      if (!costCenters) {
        return ''
      }
      costCenters = costCenters
        .flatMap(location => location.cost_centers)
        .find(itm => {
          return itm.value == costCenter
        })

      return costCenters?.name
    },

    getQuotingTimeEntries() {
      return new Promise((resolve, reject) => {
        const params = {
          action: 'get_quoting_entries_for_in_progress_quotes'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            this.quoteTimeEntries = data.hoursRemaining
            this.setTableData()
            resolve()
          })
          .catch(data => {
            this.$snack.open(data.message || 'Problem getting quote time entries', 'error')
            reject()
          })
      })
    },

    doSearch(searchAll = false) {
      return new Promise((resolve, reject) => {
        const filters = searchAll
          ? {
              status: this.status
            }
          : {
              fiscalYear: this.fiscalYear === 'all' ? '' : this.fiscalYear,
              customer: this.customer,
              quotedBy: this.quotedBy,
              status: this.status,
              dueStatus: this.showDueStatus,
              salesPerson: this.salesPerson,
              captain: this.captain,
              cost_group_code: this.cost_center,
              region: this.region,
              employeeAny: this.employeeAny,
              showCancelled: this.showCancelled
            }

        this.$bus.$emit('setWaiting', { name: 'searchQuote', message: 'Getting Data' })

        const data = {
          action: 'quote_search',
          search: this.searchKeyword,
          filters
        }

        appFuncs
          .shRequest(data)
          .then(quotes => {
            this.quotes = quotes
            if (searchAll) {
              this.clearFilters()
            }
            resolve()
          })
          .catch(err => {
            console.error(err)
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'searchQuote')
          })
      })
    },

    debouncedSearch: debounce(function () {
      this.doSearch()
    }, 300),

    clearFilters(clearSearch = false) {
      if (clearSearch) {
        this.searchKeyword = ''
      }
      this.fiscalYear = 'all'
      this.customer = ''
      this.quotedBy = ''
      this.salesPerson = ''
      this.captain = ''
      this.employeeAny = []
      this.cost_center = ''
      this.showDueStatus = ''
      this.region = null
      this.showCancelled = true
      if (this.$refs.regionFilter) {
        this.$refs.regionFilter.region = null
      }
    }
  },

  async mounted() {
    const emCostGroupsDetail = this.$store.dispatch('getEmCostGroups')
    const configuredCostCenters = this.$store.dispatch('getConfiguredCostCenters')
    const contacts = this.$store.dispatch('getCustomerContacts')
    const customers = this.$store.dispatch('getCustomers')
    const employees = this.$store.dispatch('getEmployees')
    const regions = this.$store.dispatch('getRegions')
    const emCostGroups = this.$store.dispatch('getEmCostGroups')

    const quotingHoursRemaining = this.getQuotingTimeEntries()

    this.$bus.$emit('setWaiting', { name: 'getData', message: 'Getting Data' })
    await Promise.all([contacts, customers, employees, regions, emCostGroups, configuredCostCenters])
      .then(() => {
        this.setTableData()
        if (this.openQuoteIncr) {
          this.editItem(null, this.openQuoteIncr)
        }
        // if (this.openQuoteIncr && !dontReopen) {
        //   this.editItem(null, this.openQuoteIncr)
        // }
      })
      .catch(err => {
        console.error(err)
        this.$snack.open('Problem fetching some quote data', 'error')
        return
      })
      .finally(() => {
        this.$bus.$emit('stopWaiting', 'getData')
      })

    // this causes quote2Edit to reload on every quote update #bad
    this.$bus.$on('quoteUpdate', $event => {
      this.doSearch()
    })

    this.$bus.$on('quoteEditNotes', obj => {
      this.editItem({ id: obj.id }, 'Notes')
    })

    const setCaptain = decodeURIComponent(this.$route.query.captain || '')
    if (setCaptain) {
      this.captain = setCaptain
    } else {
      this.employeeAny = [this.viewAsEmployeeId || this.userID]
    }

    const dueStatus = decodeURIComponent(this.$route.query.dueStatus || '')
    if (dueStatus) {
      this.showDueStatus = dueStatus
    }

    this.changeTab('Quote in Progress')
    this.$nextTick(() => {
      this.skipFetching = false
    })
  },

  watch: {
    $route(to, from) {
      if (to.params.openQuoteIncr) {
        this.editItem(null, to.params.openQuoteIncr)
      }
    },

    quotes() {
      this.setTableData()
    },

    status() {
      this.debouncedSearch()
    },

    fiscalYear() {
      this.debouncedSearch()
    },

    customer() {
      this.debouncedSearch()
    },

    quotedBy() {
      this.debouncedSearch()
    },

    salesPerson() {
      this.debouncedSearch()
    },

    // captain() {
    //   this.debouncedSearch()
    // },

    // employeeAny: {
    //   handler() {
    //     if (!this.skipFetching) {
    //       this.debouncedSearch()
    //     }
    //   },
    //   // deep: true
    // },

    cost_center() {
      this.debouncedSearch()
    },

    region() {
      this.debouncedSearch()
    },

    showCancelled() {
      this.debouncedSearch()
    }

    // showDueStatus() {
    //   if (!this.skipFetching) {
    //     this.debouncedSearch()
    //   }
    // }
  },

  beforeDestroy() {
    this.showCancelled = true
    this.$bus.$off(['quoteUpdate', 'quoteEditNotes'])
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.tab-section {
  position: relative;
}

.quote-filters .row > div {
  margin-bottom: 5px;
}

.legend {
  padding: 10px;
  text-align: right;
  font-size: 0;

  > div {
    display: inline-block;
    background: #fff;
    padding: 5px 10px 5px 5px;
    font-size: 14px;
    cursor: pointer;

    span {
      width: 26px;
      height: 26px;
      display: inline-block;
      line-height: 26px;
      margin: 5px;
      vertical-align: middle;

      &.green {
        background: #dafee5;
      }

      &.yellow {
        background: #fdf1c1;
      }

      &.orange {
        background: #ffce9a;
      }

      &.red {
        background: #ffc0b5;
      }

      &.blue {
        background: #daebfe;
      }

      &.purple {
        background: #f3dafe;
      }
    }
  }
}
</style>

<style lang="scss">
td.show-as-link {
  text-decoration: underline;
}
</style>

<style lang="scss" scoped>
.search-input-group {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  input {
    width: 60%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .search-buttons {
    display: flex;
    gap: 10px;
    flex: 1;

    @media (max-width: 768px) {
      width: 100%;
    }

    .btn {
      white-space: nowrap;
      padding: 6px 12px;
      flex: 1;
    }
  }
}
</style>
