<template>
  <div>
    <div class="widget-table basic-app-widget sh-widget wide-large card" style="min-height: 410px">
      <div class="content">
        <h4>{{ this.departmentName }} Projects</h4>
        <div class="table-legend">
          <div class="table-legend-item">
            <span class="green" />
            4 or Less
          </div>
          <div class="table-legend-item">
            <span class="yellow" />
            More than 4
          </div>
          <div class="table-legend-item">
            <span class="red" />
            More than 8
          </div>
        </div>
        <tabulator-table
          :table-data="tableDataProjects"
          :table-columns="tableColumns"
          table-fit="fitColumns"
          :table-condensed="false"
          empty-text="(none)"
          @colClick="colClick"
          :selectable="false"
          :row-formatter="rowFormatter"
          ref="projectsTable"
        />
      </div>
      <waiting-spinner :contained="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import snack from 'components/UIComponents/Snackbar'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
export default {
  components: {
    TabulatorTable,
    WaitingSpinner
  },

  data() {
    return {
      tableDataProjects: [
        { slug: 'time_card_approval', item: 'Time Card Approval', qty: 0, age: null, details: 'Number of time cards needing approval.' },
        // { slug: 'invoice_approval', item: 'Invoice Approval' },
        { slug: 'missing_job_po', item: 'Missing Job PO', qty: 0, age: null, details: "Only consider jobs, not WO's" },
        // { slug: 'missing_wo_po', item: 'Missing WO PO', qty: 0, age: null, details: "Only consider WO's, not jobs" },
        { slug: 'stagnant', item: 'Stagnant', qty: 0, age: null, details: "Jobs and WO's that have become stagnant" },
        // { slug: 'waiting_signoff', item: 'Waiting Signoff', qty: null, age: null, details: 'Jobs and WOs awaiting signoff' },
        { slug: 'sign_off_sent', item: 'Waiting Signoff', qty: 0, age: null, details: 'Jobs and WOs awaiting signoff' },
        { slug: 'unresponsive_signoff', item: 'Unresponsive Signoff', qty: 0, age: null, details: "Count up any jobs/WO that have sign off's sent for the person." }
      ]
    }
  },

  computed: {
    ...mapGetters(['userAuthedData', 'employees', 'viewAsEmployeeId', 'selectedDepartment', 'emCostGroups']),

    tableColumns() {
      return [
        {
          title: '',
          field: 'item',
          width: 200,
          headerSort: false,
          cssClass: 'is-link',
          formatter: row => {
            const titleList = ['Quotes:', 'Billing:', 'Project:']
            if (titleList.includes(row.getData().item)) {
              return `<strong>${row.getData().item}</strong>`
            }
            return row.getData().item
          }
        },
        {
          title: '',
          field: 'qty',
          width: 250,
          headerSort: false,
          formatter: function (cell, formatterParams) {
            const value = cell.getValue()
            let color = ''

            if (value === null) {
              return '<div style="height:36px; width:20px"></div>'
            }

            // Define color based on value ranges
            if (value <= 4) {
              color = 'linear-gradient(90deg, rgba(1,246,63,1) 0%, rgba(14,247,70,1) 100%)'
            } else if (value <= 8) {
              color = 'linear-gradient(90deg, rgba(246,212,52,1) 0%, rgba(255,224,58,1) 100%)'
            } else if (value > 8) {
              color = 'linear-gradient(90deg, rgba(254,89,96,1) 0%, rgb(241 0 9) 100%)'
            }

            // Return the progress bar with the determined color
            return `
            <div style="position: relative; display: flex;justify-content: center;align-items: center; height: 36px">
                <div style="background:${color}; width: ${(value / 20) * 100 > 100 ? 100 : (value / 20) * 100}%; height: 100%;position: absolute;left: 0;border-radius: 7px;"></div>
                <div style="position: absolute; font-size: 14px; font-weight:600; color: #000">
                  ${value || 0}
                </div>
              </div>`
          },
          formatterParams: { min: 0, max: 20 }
        },
        {
          title: 'Age',
          field: 'age',
          width: 80,
          headerSort: false
        }
        // {
        //   title: 'Percent Total',
        //   field: 'percentTotal',
        //   width: 100,
        //   headerSort: false
        // }
      ]
    },

    departmentName() {
      if (this.emCostGroups.length) {
        if (this.selectedDepartment) {
          const department = this.emCostGroups.filter(_group => _group.Cost_Group_Code === this.selectedDepartment)[0]
          if (department) return department.Cost_Group_Description
        }
      }
      return 'My'
    }
  },

  methods: {
    colClick(obj) {
      const slug = (obj.row || {}).slug

      const path = {
        path: '/app/job-wo-report',
        query: {}
      }

      const pm = this.viewAsEmployeeId
      const dept = this.selectedDepartment

      switch (slug) {
        case 'time_card_approval':
          this.$router.push({ path: '/app/time-approval' })
          return
        case 'missing_job_po':
          path.query.show = 'jobs-missing-po'
          if (pm) path.query.pm = pm
          if (dept) path.query.dept = dept
          break
        case 'missing_wo_po':
          path.query.show = 'wos-missing-po'
          if (pm) path.query.pm = pm
          if (dept) path.query.dept = dept
          break
        case 'stagnant':
          path.query.show = 'stagnant'
          if (pm) path.query.pm = pm
          if (dept) path.query.dept = dept
          break
        case 'sign_off_sent':
          path.query.show = 'sign-off-sent'
          if (pm) path.query.pm = pm
          if (dept) path.query.dept = dept
          break
        case 'unresponsive_signoff':
          path.query.show = 'unresponsive-signoff'
          if (pm) path.query.pm = pm
          if (dept) path.query.dept = dept
          break
        default:
          console.log('Unknown column slug:', slug)
          return
      }

      this.$router.push(path)
    },

    rowFormatter(row) {
      const data = row.getData()
      row.getElement().style.display = data.hidden ? 'none' : ''
    },

    async getTimecardApprovalStats() {
      try {
        const res = await appFuncs.shHttp({ action: 'get_approvals_on_base_of_pms', employee_code: this.viewAsEmployeeId })
        const updateRow = (slug, data) => {
          const row = this.tableDataProjects.find(row => row.slug === slug)
          if (row) Object.assign(row, data, { hidden: false })
        }

        updateRow('quotes_title', {})
        updateRow('time_card_approval', { qty: res?.data[0]?.entries, age: res?.data[0]?.age })
        this.redrawProjectsTable()
      } catch (error) {
        snack.open('Problem getting quote stats', 'error')
        console.log(error)
      }
    },

    async getJobMissingPOStats() {
      try {
        const res = await appFuncs.shHttp({
          action: 'get_job_missing_po_count',
          employee_code: this.viewAsEmployeeId,
          cost_group_code: this.selectedDepartment
        })
        const updateRow = (slug, data) => {
          const row = this.tableDataProjects.find(row => row.slug === slug)
          if (row) Object.assign(row, data, { hidden: false })
        }

        updateRow('missing_job_po', { qty: res?.data?.job_count, age: res?.data?.age })
        this.redrawProjectsTable()
      } catch (error) {
        snack.open('Problem getting quote stats', 'error')
        console.log(error)
      }
    },

    async getWOMissingPOStats() {
      try {
        const res = await appFuncs.shHttp({ action: 'get_wo_missing_po_count', employee_code: this.viewAsEmployeeId })
        const updateRow = (slug, data) => {
          const row = this.tableDataProjects.find(row => row.slug === slug)
          if (row) Object.assign(row, data, { hidden: false })
        }

        updateRow('missing_wo_po', { qty: res?.data?.wo_count, age: res?.data?.age })
        this.redrawProjectsTable()
      } catch (error) {
        snack.open('Problem getting quote stats', 'error')
        console.log(error)
      }
    },

    async getStagnantProjectStats() {
      try {
        const res = await appFuncs.shHttp({
          action: 'get_stagnant_projects_count',
          employee_code: this.viewAsEmployeeId,
          cost_group_code: this.selectedDepartment
        })
        const updateRow = (slug, data) => {
          const row = this.tableDataProjects.find(row => row.slug === slug)
          if (row) Object.assign(row, data, { hidden: false })
        }

        updateRow('stagnant', { qty: res?.data?.count, age: res?.data?.age })
        this.redrawProjectsTable()
      } catch (error) {
        snack.open('Problem getting quote stats', 'error')
        console.log(error)
      }
    },

    async getJobWOSignOffStats() {
      try {
        const res = await appFuncs.shHttp({
          action: 'get_sign_off_job_wo_count_and_age',
          employee_code: this.viewAsEmployeeId,
          cost_group_code: this.selectedDepartment
        })
        const updateRow = (slug, data) => {
          const row = this.tableDataProjects.find(row => row.slug === slug)
          if (row) Object.assign(row, data, { hidden: false })
        }

        updateRow('unresponsive_signoff', { qty: res?.data['unresponsive']?.count, age: res?.data['unresponsive']?.age || 0 })
        updateRow('waiting_signoff', { qty: res?.data['needs-signature-and-sign-off-sent']?.count, age: res?.data['needs-signature-and-sign-off-sent']?.age || 0 })
        updateRow('sign_off_sent', { qty: res?.data['sign-off-sent']?.count, age: res?.data['sign-off-sent']?.age || 0 })
        this.redrawProjectsTable()
      } catch (error) {
        snack.open('Problem getting quote stats', 'error')
        console.log(error)
      }
    },

    async getData() {
      this.getTimecardApprovalStats()
      this.getJobMissingPOStats()
      // this.getWOMissingPOStats()
      this.getStagnantProjectStats()
      this.getJobWOSignOffStats()
    },

    redrawProjectsTable() {
      if (this.$refs.projectsTable?.table) {
        this.$refs.projectsTable.updateTable()
      }
    }
  },

  mounted() {
    // this.getData()
    this.$store.dispatch('getEmployees')
  },

  watch: {
    viewAsEmployeeId: {
      handler() {
        this.getData()
      },
      immediate: true
    },
    selectedDepartment: {
      handler() {
        this.getData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table-legend {
  color: #ababab;
  font-weight: 500;
  min-height: 25px;
  display: flex;
  gap: 20px;
  padding: 20px 8px 0;
  z-index: 1;
  position: relative;

  .table-legend-item {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 6px;

    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      border-radius: 50%;

      &.red {
        background: linear-gradient(90deg, rgba(254, 89, 96, 1) 0%, rgba(254, 67, 74, 1) 100%);
      }

      &.green {
        background: linear-gradient(90deg, rgba(1, 246, 63, 1) 0%, rgba(14, 247, 70, 1) 100%);
      }

      &.yellow {
        background: linear-gradient(90deg, rgba(246, 212, 52, 1) 0%, rgba(255, 224, 58, 1) 100%);
      }
    }
  }
}

.widget-row {
  padding: 0 15px;
}

@media screen and (max-width: 700px) {
  .table-legend {
    justify-content: space-between;
    gap: 0;

    .table-legend-item {
      font-size: 12px;
      gap: 3px;
    }
  }

  .widget-table {
    margin-right: 0 !important;
    padding: 20px 10px !important;

    .hours-tracker-title {
      margin-bottom: 20px !important;
    }
  }
}
</style>
