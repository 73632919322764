<template>
  <div>
    <div class="sh-widget basic-app-widget widget-table wide-large card hours-tracker-widget" style="min-height: 470px">
      <div>
        <div class="content">
          <div class="widget-header" style="margin-bottom: 20px">
            <h4>Hours Tracker</h4>
            <div class="row">
              <div class="col-sm-12 col-md-6" v-if="showDepartmentSelector">
                <div class="form-group">
                  <select-department-as-employee :value="selectedDepartment" :employee-id="employeeId" />
                </div>
              </div>

              <div class="col-sm-12 col-md-5 pull-right">
                <div class="form-group">
                  <span
                    style="padding-left: 3px"
                    class="ti-info-alt"
                    v-tooltip="{
                      content: 'Fiscal year shown is 1 year behind actual fiscal year',
                      placement: 'bottom',
                      hideOnTargetClick: false,
                      delay: { show: 200, hide: 2500 },
                      offset: 5,
                      classes: ['wide']
                    }"
                  />
                  <select-field
                    :options="Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => ({ value: (fiscalYear - i).toString() }))"
                    :option-display-keys="['value']"
                    :option-val="'value'"
                    v-model="viewFiscalYear"
                    @selectItem="val => this.getHoursStats()"
                    style="display: inline-block; margin: 0 0 0 10px"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="content-inner">
            <tabulator-table
              :table-data="tableDataHours"
              :table-columns="tableColumnsHours"
              table-fit="fitColumns"
              :table-condensed="false"
              empty-text="(none)"
              @colClick="colClick"
              :selectable="false"
              ref="hoursTable"
              :header-visible="false"
            />
            <DonutChart :series="[30, 50, 20]" :dataHours="tableDataHours" :labels="['Red', 'Blue', 'Yellow']" />
          </div>
        </div>
      </div>
      <waiting-spinner :contained="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectDepartmentAsEmployee from 'components/Dashboard/Widgets/SelectDepartmentAsEmployee'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import snack from 'components/UIComponents/Snackbar'
import appFuncs from 'appFuncs'
import DonutChart from 'components/UIComponents/DonutChart.vue'
import SelectField from 'components/UIComponents/SelectField'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
export default {
  components: {
    SelectDepartmentAsEmployee,
    SelectField,
    TabulatorTable,
    DonutChart,
    WaitingSpinner
  },

  props: {
    employeeId: {
      type: [String, Number],
      default: null
    },
    showDepartmentSelector: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tableDataHours: [
        { slug: 'billable', item: 'Billable', qty: 0, percentTotal: 0, legendColor: '#01f63f' },
        { slug: 'unbillable', item: 'Unbillable', qty: 0, percentTotal: 0, legendColor: '#fe434a' },
        { slug: 'quoting', item: 'Quoting', qty: 0, percentTotal: 0, legendColor: '#e072e6' },
        { slug: 'sick', item: 'Sick', qty: 0, percentTotal: 0, legendColor: '#fff36e' },
        { slug: 'safety', item: 'Safety', qty: 0, percentTotal: 0, legendColor: '#6ee5ff' },
        { slug: 'holiday', item: 'Holiday', qty: 0, percentTotal: 0, legendColor: '#ffc76e' },
        { slug: 'vacation', item: 'Vacation', qty: 0, percentTotal: 0, legendColor: '#ffa76e' }
        // { slug: 'total', item: 'Total', qty: 0, percentTotal: 0 },
      ],
      tableData: [],
      viewFiscalYear: this.fiscalYear
    }
  },

  computed: {
    ...mapGetters(['userAuthedData', 'employees', 'fiscalYear', 'emCostGroups', 'selectedDepartment']),

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    tableColumnsHours() {
      return [
        {
          title: '',
          field: 'item',
          width: 200,
          headerSort: false,
          formatter: row => {
            const titleList = ['Quotes:', 'Billing:', 'Project:']
            if (titleList.includes(row.getData().item)) {
              return `<strong>${row.getData().item}</strong>`
            }
            return `<div style="display: flex; gap: 20px;">
                <div style="width: 20px; height: 20px; background: ${row.getData().legendColor}; border-radius: 100px"></div>
                <div>${row.getData().item}</div>
              </div>`
          }
        },
        {
          title: 'Hours',
          field: 'qty',
          width: 100,
          headerSort: false,
          cssClass: 'hours-tracker-qty',
          formatter: row => {
            const data = row.getData()
            if (!data.qty) return '0 hrs'
            return `${this.formatNumber(data.qty, true, true)} hrs`
          }
        }
      ]
    }
  },

  methods: {
    colClick(obj) {
      const slug = (obj.row || {}).slug
    },

    async getHoursStats() {
      this.$bus.$emit('setWaiting', { name: 'getEmployeeFiscalYearHours', message: 'Getting employee fiscal year hours' })

      if (!this.fiscalYear) {
        return
      }

      try {
        const res = await appFuncs.shHttp({
          action: 'get_employee_fiscal_year_hours',
          employee_code: this.employeeId,
          fiscalYear: this.viewFiscalYear,
          cost_group_code: this.selectedDepartment ? this.selectedDepartment : null
        })
        const updateRow = (slug, data) => {
          const row = this.tableDataHours.find(row => row.slug === slug)
          if (row) Object.assign(row, data, { hidden: false })
        }

        updateRow('billable', { qty: res?.data?.['Billable'] || 0, percentTotal: res?.data?.['billablePercentTotal'] || 0 })
        updateRow('unbillable', { qty: res?.data?.['Unbillable'] || 0, percentTotal: res?.data?.['unbillablePercentTotal'] || 0 })
        updateRow('quoting', { qty: res?.data?.['Quoting'] || 0, percentTotal: res?.data?.['quotingPercentTotal'] || 0 })
        updateRow('sick', { qty: res?.data?.['Sick'] || 0, percentTotal: res?.data?.['sickPercentTotal'] || 0 })
        updateRow('safety', { qty: res?.data?.['Safety'] || 0, percentTotal: res?.data?.['safetyPercentTotal'] || 0 })
        updateRow('holiday', { qty: res?.data?.['Holiday'] || 0, percentTotal: res?.data?.['holidayPercentTotal'] || 0 })
        updateRow('vacation', { qty: res?.data?.['Vacation'] || 0, percentTotal: res?.data?.['vacationPercentTotal'] || 0 })
        updateRow('total', { qty: res?.data?.['Total'] || 0, percentTotal: res?.data?.['totalPercentTotal'] || 0 })

        this.redrawHoursTable()
      } catch (error) {
        snack.open('Problem getting hours stats', 'error')
        console.log(error)
      } finally {
        this.$bus.$emit('stopWaiting', 'getEmployeeFiscalYearHours')
      }
    },

    async getData() {
      this.getHoursStats()
    },

    redrawHoursTable() {
      if (this.$refs.hoursTable?.table) {
        this.$refs.hoursTable.updateTable()
      }
    }
  },

  mounted() {
    this.getData()
    this.$store.dispatch('getEmployees')
  },

  watch: {
    employeeId() {
      this.getData()
    },

    fiscalYear: {
      handler() {
        this.viewFiscalYear = this.fiscalYear
        this.getData()
      },
      immediate: true
    },

    selectedDepartment: {
      handler() {
        this.getData()
      }
    },

    viewAsEmployeeId: {
      handler() {
        this.getData()
      }
    }
  }
}
</script>

<style lang="scss">
.hours-tracker-widget {
  .tabulator {
    scrollbar-width: none;
    margin-top: -10px;

    .tabulator-header {
      border: none;

      .tabulator-col {
        &[tabulator-field='qty'] {
          margin-right: 20px;
        }
        &.hours-tracker-qty {
          margin-right: 0;
        }
        &[tabulator-field='percentTotal'] {
          .tabulator-col-content {
            padding: 10px 0;
            text-align: right;
          }
        }
        &[tabulator-field='qty'] {
          margin-right: 10px;
          .tabulator-col-content {
            padding: 10px 0;
          }
        }
      }
    }
    .tabulator-table {
      overflow: hidden !important;

      .tabulator-row {
        border: none;
        margin-bottom: 10px;

        .tabulator-cell {
          color: #9da2a5;

          &[tabulator-field='qty'],
          &[tabulator-field='percentTotal'] {
            padding: 0 !important;
            border-radius: 7px;
            background: #f6f6f6;
            margin-right: 15px;
          }

          div[data-max='10'] {
            border-radius: 7px;
          }
        }

        .hours-tracker-qty {
          background: none !important;
          padding: 8px 0 !important;
          margin-right: 10px !important;
        }
      }
    }

    @media screen and (max-width: 700px) {
      [tabulator-field='qty'] {
        max-width: 40%; /* Set minimum width for the "name" column */
        margin-right: 10px !important;
      }

      [tabulator-field='item'] {
        max-width: 40%; /* Set minimum width for the "name" column */
      }

      [tabulator-field='age'] {
        max-width: 20%; /* Set minimum width for the "name" column */
      }

      [tabulator-field='percentTotal'] {
        max-width: 40%; /* Set minimum width for the "name" column */
      }

      .hours-tracker-qty {
        // width: 50px !important;
      }
    }
  }
  .content {
    .content-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .chart-container {
        position: relative;
        right: 25px;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .content-inner {
      flex-direction: column;
      .chart-container {
        right: 0;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.table-legend {
  color: #ababab;
  font-weight: 500;
  min-height: 25px;
  display: flex;
  gap: 20px;
  padding: 20px 8px 0;
  z-index: 1;
  position: relative;

  .table-legend-item {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 6px;

    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      border-radius: 50%;

      &.red {
        background: linear-gradient(90deg, rgba(254, 89, 96, 1) 0%, rgba(254, 67, 74, 1) 100%);
      }

      &.green {
        background: linear-gradient(90deg, rgba(1, 246, 63, 1) 0%, rgba(14, 247, 70, 1) 100%);
      }

      &.yellow {
        background: linear-gradient(90deg, rgba(246, 212, 52, 1) 0%, rgba(255, 224, 58, 1) 100%);
      }
    }
  }

  @media screen and (max-width: 700px) {
    justify-content: space-between;
    gap: 0;

    .table-legend-item {
      font-size: 12px;
      gap: 3px;
    }
  }
}
</style>
