<template>
  <div>
    <div class="widget-table basic-app-widget sh-widget wide-large card" style="min-height: 470px">
      <div class="content">
        <h4>{{ this.departmentName }} Billings</h4>
        <div class="table-legend">
          <div class="table-legend-item">
            <span class="green" />
            4 or Less
          </div>
          <div class="table-legend-item">
            <span class="yellow" />
            More than 4
          </div>
          <div class="table-legend-item">
            <span class="red" />
            More than 8
          </div>
        </div>
        <tabulator-table
          :table-data="tableDataBilling"
          :table-columns="tableColumns"
          table-fit="fitColumns"
          :table-condensed="false"
          empty-text="(none)"
          @colClick="colClick"
          :selectable="false"
          :row-formatter="rowFormatter"
          ref="billingTable"
        />
      </div>
      <waiting-spinner :contained="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import snack from 'components/UIComponents/Snackbar'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
export default {
  components: {
    TabulatorTable,
    WaitingSpinner
  },

  data() {
    return {
      tableDataBilling: [
        {
          slug: 'waiting_approval',
          item: 'Waiting Approval',
          qty: 0,
          age: null,
          details: 'Count oldest invoice approval task date (i.e. if its Oct 10 and oldest date for an invoice approval task was Oct 10 then count = 9)',
          hidden: true
        },
        {
          slug: 'feedback_requested',
          item: 'Feedback Requested',
          qty: 0,
          age: null,
          details: 'Count oldest invoice approval task date (i.e. if its Oct 10 and oldest date for an invoice approval task was Oct 10 then count = 9)',
          hidden: true
        },
        {
          slug: 'upload_draw_approval',
          item: 'Upload Draw Approval',
          qty: 0,
          age: null,
          details: 'Count oldest invoice approval task date (i.e. if its Oct 10 and oldest date for an invoice approval task was Oct 10 then count = 9)',
          hidden: true
        },
        {
          slug: 'files_requested',
          item: 'Files Requested',
          qty: 0,
          age: null,
          details: 'Count oldest invoice approval task date (i.e. if its Oct 10 and oldest date for an invoice approval task was Oct 10 then count = 9)',
          hidden: true
        },
        {
          slug: 'missing_po',
          item: 'Missing PO',
          qty: null,
          age: 0,
          details: 'Count oldest invoice approval task date (i.e. if its Oct 10 and oldest date for an invoice approval task was Oct 10 then count = 9)',
          hidden: true
        },
        {
          slug: 'sign_off_missing',
          item: 'Missing Sign Off',
          qty: 0,
          age: null,
          details: 'Count oldest invoice approval task date (i.e. if its Oct 10 and oldest date for an invoice approval task was Oct 10 then count = 9)',
          hidden: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['userAuthedData', 'employees', 'viewAsEmployeeId', 'selectedDepartment', 'emCostGroups']),

    tableColumns() {
      return [
        {
          title: '',
          field: 'item',
          width: 200,
          headerSort: false,
          cssClass: 'is-link',
          formatter: row => {
            const titleList = ['Quotes:', 'Billing:', 'Project:']
            if (titleList.includes(row.getData().item)) {
              return `<strong>${row.getData().item}</strong>`
            }
            return row.getData().item
          }
        },
        {
          title: '',
          field: 'qty',
          width: 250,
          headerSort: false,
          formatter: function (cell, formatterParams) {
            const value = cell.getValue()
            let color = ''

            if (value === null) {
              return '<div style="height:36px; width:20px"></div>'
            }

            // Define color based on value ranges
            if (value <= 4) {
              color = 'linear-gradient(90deg, rgba(1,246,63,1) 0%, rgba(14,247,70,1) 100%)'
            } else if (value <= 8) {
              color = 'linear-gradient(90deg, rgba(246,212,52,1) 0%, rgba(255,224,58,1) 100%)'
            } else if (value > 8) {
              color = 'linear-gradient(90deg, rgba(254,89,96,1) 0%, rgb(241 0 9) 100%)'
            }

            // Return the progress bar with the determined color
            return `
            <div style="position: relative; display: flex;justify-content: center;align-items: center; height: 36px">
                <div style="background:${color}; width: ${(value / 20) * 100 > 100 ? 100 : (value / 20) * 100}%; height: 100%;position: absolute;left: 0;border-radius: 7px;"></div>
                <div style="position: absolute; font-size: 14px; font-weight:600; color: #000">
                  ${value || 0}
                </div>
              </div>`
          },
          formatterParams: { min: 0, max: 20 }
        },
        {
          title: 'Age',
          field: 'age',
          width: 80,
          headerSort: false
        }
        // {
        //   title: 'Percent Total',
        //   field: 'percentTotal',
        //   width: 100,
        //   headerSort: false
        // }
      ]
    },

    departmentName() {
      if (this.emCostGroups.length) {
        if (this.selectedDepartment) {
          const department = this.emCostGroups.filter(_group => _group.Cost_Group_Code === this.selectedDepartment)[0]
          if (department) return department.Cost_Group_Description
        }
      }
      return 'My'
    }
  },

  methods: {
    colClick(obj) {
      const slug = (obj.row || {}).slug

      switch (slug) {
        case 'waiting_approval':
          this.$router.push({ path: '/app/tasks', query: { show: 'waiting-pm-approval' } })
          break
        case 'feedback_requested':
          this.$router.push({ path: '/app/tasks', query: { show: 'feedback-requested' } })
          break
        case 'upload_draw_approval':
          this.$router.push({ path: '/app/tasks', query: { show: 'upload-draw-approval' } })
          break
        case 'files_requested':
          this.$router.push({ path: '/app/tasks', query: { show: 'files-requested' } })
          break
        case 'missing_po':
          this.$router.push({ path: '/app/tasks', query: { show: 'missing-po' } })
          break
        case 'sign_off_missing':
          this.$router.push({ path: '/app/tasks', query: { show: 'sign-off-missing' } })
          break
        // case 'invoice_approval':
        //   this.$router.push({ path: '/app/tasks', query: { show: 'invoice-approval' } })
        //   break
        default:
          console.log('Unknown column slug:', slug)
      }
    },

    rowFormatter(row) {
      const data = row.getData()
      row.getElement().style.display = data.hidden ? 'none' : ''
    },

    async getBillingTaskStats() {
      try {
        const res = await appFuncs.shHttp({
          action: 'get_billing_subtasks_age_counts',
          employee_code: this.viewAsEmployeeId,
          cost_group_code: this.selectedDepartment
        })
        res.data.forEach(item => {
          const mapping = {
            WA: 'waiting_approval',
            IA: 'invoice_approval',
            FR: 'feedback_requested',
            UDA: 'upload_draw_approval',
            RF: 'files_requested',
            MPO: 'missing_po',
            SOM: 'sign_off_missing'
          }
          const slug = mapping[item.task_type]
          if (slug) {
            const row = this.tableDataBilling.find(row => row.slug === slug)
            if (row) Object.assign(row, { qty: item.count, age: item.oldest_days, hidden: false })
          }
        })
        this.redrawBillingTable()
      } catch (error) {
        snack.open('Problem getting billing task stats', 'error')
        console.log(error)
      }
    },

    async getData() {
      this.getBillingTaskStats()
    },

    redrawBillingTable() {
      if (this.$refs.billingTable?.table) {
        this.$refs.billingTable.updateTable()
      }
    }
  },

  mounted() {
    this.getData()
    this.$store.dispatch('getEmployees')
  },

  watch: {
    viewAsEmployeeId: {
      handler() {
        this.getData()
      },
      immediate: true
    },
    selectedDepartment: {
      handler() {
        this.getData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table-legend {
  color: #ababab;
  font-weight: 500;
  min-height: 25px;
  display: flex;
  gap: 20px;
  padding: 20px 8px 0;
  z-index: 1;
  position: relative;

  .table-legend-item {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 6px;

    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      border-radius: 50%;

      &.red {
        background: linear-gradient(90deg, rgba(254, 89, 96, 1) 0%, rgba(254, 67, 74, 1) 100%);
      }

      &.green {
        background: linear-gradient(90deg, rgba(1, 246, 63, 1) 0%, rgba(14, 247, 70, 1) 100%);
      }

      &.yellow {
        background: linear-gradient(90deg, rgba(246, 212, 52, 1) 0%, rgba(255, 224, 58, 1) 100%);
      }
    }
  }
}

.widget-row {
  padding: 0 15px;
}

@media screen and (max-width: 700px) {
  .table-legend {
    justify-content: space-between;
    gap: 0;

    .table-legend-item {
      font-size: 12px;
      gap: 3px;
    }
  }

  .widget-table {
    margin-right: 0 !important;
    padding: 20px 10px !important;

    .hours-tracker-title {
      margin-bottom: 20px !important;
    }
  }
}
</style>
