
<div class="">
  <set-view-as-employee v-if="isAdmin" />
  <br />

  <div v-if="isPM">
    <button :class="'btn'" @click="addJob">Add Job</button>
    <br /><br />
  </div>

  <div class="tab-section white">
    <ul class="nav nav-tabs">
      <li role="presentation" :class="[{ active: tab == 'my' }]">
        <a href="#" @click=";(tab = 'my'), setTable()"> My Jobs/WOs </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'all' }]" v-if="isCaptain">
        <a href="#" @click=";(tab = 'all'), setTable()"> All Jobs/WOs </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'missing' }]" v-if="isCaptain">
        <a
          href="#"
          @click="
            tab = 'missing'
            filterMissingPO = 'yes'
            clearAllFilters('filterMissingPO')
            setTable()
          "
        >
          Show Missing PO's
        </a>
      </li>
    </ul>

    <div :class="['card', { Main: tab == 'my' }]">
      <div class="content">
        <div v-if="tab !== 'missing'" class="row report-filters">
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Type</label>
            <select-field
              :options="[
                { name: 'Jobs', value: 'jobs' },
                { name: 'WOs', value: 'wos' },
                { name: 'Jobs & WOs', value: 'all' }
              ]"
              :option-display-keys="['name']"
              :option-val="'value'"
              v-model="filterType"
              :allow-clear="true"
              @selectItem="setTable()"
            />
          </div>
          <div v-if="filterType === 'jobs' && tab !== 'my'" class="col-sm-12 col-md-3 col-lg-2">
            <label>Jobs</label>
            <select-field
              v-model="filterJob"
              :options="filteredJobs"
              :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
              :option-val="'Job_Number'"
              :empty-option-text="'No items.'"
              @selectItem="(clearAllFilters('filterJob'), setTable())"
              :allow-clear="true"
            />
          </div>
          <div v-if="filterType === 'wos' && tab !== 'my'" class="col-sm-12 col-md-3 col-lg-2">
            <label>WOs</label>
            <select-field
              v-model="filterWO"
              :options="filteredWOs"
              :option-display-keys="['WO_Number', 'Name', 'Summary_Description']"
              :option-val="'WO_Number'"
              :empty-option-text="'No items.'"
              @selectItem="(clearAllFilters('filterWO'), setTable())"
              :allow-clear="true"
            />
          </div>
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Customer</label>
            <select-field
              :options="customerList"
              :option-display-keys="['Name']"
              :option-val="'Customer_Code'"
              @selectItem="(clearAllFilters('filterCustomer'), setTable())"
              v-model="filterCustomer"
              :allow-clear="true"
            />
          </div>
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Project Lead</label>
            <select-field-multi
              :options="employeesWithRoleFlags"
              :option-display-keys="['Employee_Name']"
              :option-val="'Employee_Code'"
              v-model="filterPM"
              :filter-on-key-val="{ key: 'hasPMRole', val: 'yes' }"
              :allow-clear="true"
              @selectItem="setTable()"
              :show-remove-warning="false"
            />
          </div>
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Captain</label>
            <select-field-multi
              :options="employeesWithRoleFlags"
              :option-display-keys="['Employee_Name']"
              :option-val="'Employee_Code'"
              v-model="filterCaptain"
              :filter-on-key-val="{ key: 'hasCaptainRole', val: 'yes' }"
              :allow-clear="true"
              @selectItem="setTable()"
              :show-remove-warning="false"
            />
          </div>
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Stage</label>
            <select-field
              :options="[...projectStages, ...signOffStages]"
              :label-text="'Stage'"
              :option-display-keys="['description']"
              :option-val="'id'"
              v-model="filterStage"
              :allow-clear="true"
              @selectItem="setTable()"
            />
          </div>
        </div>

        <div v-if="tab !== 'missing'" class="row report-filters">
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Division</label>
            <select-field :options="divisionCostGroupOptions" :option-display-keys="['name']" :option-val="'value'" v-model="filterDivision" :allow-clear="true" @selectItem="setTable()" />
          </div>

          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Missing PO</label>
            <select-field
              :options="[
                { name: 'Yes', value: 'yes' },
                { name: 'No', value: 'no' }
              ]"
              :option-display-keys="['name']"
              :option-val="'value'"
              v-model="filterMissingPO"
              :allow-clear="false"
              @selectItem="setTable()"
            />
          </div>

          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Sort</label>
            <select-field
              :options="[
                { name: 'Default', value: '' },
                { name: 'Project Lead', value: 'pm_name' },
                { name: 'Captain', value: 'captain_name' },
                { name: 'Sales Person', value: 'sales_person_name' },
                { name: 'Region', value: 'region' },
                { name: 'Stage', value: 'stage' },
                { name: 'Cost Center', value: 'cost_center' },
                { name: 'Department', value: 'department' },
                { name: 'Last Status Note Date', value: 'last_status_note_date' },
                { name: 'Under/Over Billing', value: 'over_under_billing' }
              ]"
              :option-display-keys="['name']"
              :option-val="'value'"
              v-model="sortOption"
              :allow-clear="false"
              @selectItem="setTable()"
            />
          </div>

          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Sort Order</label>
            <select-field
              :options="[
                { name: 'ASC', value: 'asc' },
                { name: 'DESC', value: 'desc' }
              ]"
              :option-display-keys="['name']"
              :option-val="'value'"
              v-model="sortOrder"
              :allow-clear="false"
              @selectItem="setTable()"
            />
          </div>

          <div class="col-sm-12 col-md-3 col-lg-2">
            <button style="margin-top: 27px" class="btn" @click="download">Download</button>
          </div>
        </div>

        <div class="row" v-if="tab !== 'missing' && tooManyResults && !tableData.length">
          <div class="col-sm-12">
            <p class="text-danger" style="padding: 20px 0">
              <strong>Too many results, filter above or <span class="is-link underlined" @click="setTable('loadAll')">load anyway.</span></strong>
            </p>
          </div>
        </div>

        <br />

        <!-- <button class="btn btn-sm" @click="downloadCSV">Download CSV</button> -->

        <tabulator-table
          ref="reportTable"
          :table-data="tableData"
          :table-columns="tableColumns"
          table-fit="fitColumns"
          :table-condensed="false"
          empty-text="(none)"
          :data-tree="false"
          :row-formatter="rowFormatter"
          :download-data-formatter="downloadDataFormatter"
          @colClick="colClick"
          Xmax-table-height="500px"
          table-height="700px"
        />
      </div>
    </div>
  </div>

  <waiting-spinner />
</div>
