<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-5">
        <set-view-as-employee v-if="isAdmin" />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-7 pull-right">
        <div class="legend">
          <div><span class="green" />&lt; 7 Days Old</div>
          <div><span class="orange" />7-14 Days Old</div>
          <div><span class="red" />14+ Days Old</div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="content">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Sub Task</label>
              <select-field :option-display-keys="['name']" :option-val="'value'" v-model="subTaskType" :allow-clear="true" :options="subTaskOptions" @selectItem="getTasks()" />
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Billing Task</label>
              <select-field :option-display-keys="['name']" :option-val="'value'" v-model="billingTaskStatus" :allow-clear="true" :options="billingTasks" @selectItem="getTasks()" />
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Approval Status</label>
              <select-field :option-display-keys="['name']" :option-val="'value'" v-model="approvalStatus" :allow-clear="true" :options="approvalStatuses" @selectItem="getTasks()" />
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Task Status</label>
              <select-field :option-display-keys="['name']" :option-val="'value'" v-model="taskStatus" :allow-clear="true" :options="statusOptions" @selectItem="getTasks()" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 10px">
            <label>Division</label>
            <select-field :options="divisionCostGroupOptions" :option-display-keys="['name']" :option-val="'value'" v-model="costGroupCode" :allow-clear="true" @selectItem="getTasks()" />
          </div>
        </div>
        <div class="row" style="">
          <div class="col-sm-12">
            <tabulator-table
              :table-data="filteredTasks"
              :table-columns="tableColumns"
              table-fit="fitColumns"
              :table-condensed="false"
              empty-text="(none)"
              @colClick="colClick"
              :row-formatter="rowFormatter"
              :initial-sort="[
                {
                  column: 'date_due',
                  dir: 'desc'
                }
              ]"
            />
          </div>
        </div>
        <div class="row" style="padding: 20px 0 0">
          <div class="col-sm-12">
            <div>
              <!-- <button class="btn" @click="addTask">Add Taskasdsdf</button> -->
              <!-- <button class="btn btn-danger" @click="$bus.$emit('modalClose', 'BillingTaskAdd')">Cancel</button>
          <button class="btn" @click="viewTasks">View Tasks</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import billingFuncs from 'mixins/billingFuncs'
import moment from 'moment'

export default {
  name: 'BillingSubTasks',
  data() {
    return {
      billingInstructions: '',
      approvalStatus: '',
      taskStatus: 'O',
      taskType: null,
      taskAssignment: null,
      billingTaskStatus: null,
      subTaskType: null,
      costGroupCode: null,
      data: []
    }
  },

  components: {
    SelectField: () => import('components/UIComponents/SelectField'),
    TabulatorTable: () => import('components/UIComponents/TabulatorTable'),
    SetViewAsEmployee: () => import('components/Dashboard/Widgets/SetViewAsEmployee')
  },

  props: {
    WO_Number: {
      type: [String, Number],
      default: null
    },
    Job_Number: {
      type: [String, Number],
      default: null
    }
  },

  mixins: [billingFuncs],

  computed: {
    ...mapGetters(['employees', 'vendorContacts', 'urls', 'vendors', 'userAuthedData', 'viewAsEmployeeId', 'customers', 'emCostGroups']),

    assignList() {
      return []
    },

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    tableColumns() {
      return [
        {
          field: 'id',
          visible: false
        },
        {
          title: 'Billing Task',
          field: 'parent_task_type',
          width: 200,
          cssClass: 'is-link',
          formatter: cell => {
            const value = cell.getValue()
            const task = this.billingTasks.find(emp => emp.value == value)
            return task ? task.name : value
          }
        },
        {
          title: 'Sub Task',
          field: 'task_type',
          width: 200,
          cssClass: 'is-link',
          formatter: cell => {
            const value = cell.getValue()
            const task = this.subTaskOptions.find(option => option.value === value)
            return task ? task.name : value
          }
        },
        // {
        //   title: 'Main Task Status',
        //   field: 'billing_task_status',
        //   cssClass: 'is-link',
        //   width: 200,
        //   formatter: cell => {
        //     const value = cell.getValue()
        //     const billingTaskStatus = this.billingTaskStatusOptions.find(option => option.value === value)
        //     return billingTaskStatus ? billingTaskStatus.name : value
        //   }
        // },
        {
          title: 'Sub Task Status',
          field: 'approval_status',
          width: 200,
          cssClass: 'is-link',
          formatter: cell => {
            const value = cell.getValue()
            const billingTaskStatus = this.approvalStatuses.find(option => option.value === value)
            return billingTaskStatus ? billingTaskStatus.name : value
          }
        },
        {
          title: 'Task Comment',
          field: 'last_task_commment',
          cssClass: 'is-link',
          width: 300,
          formatter: cell => {
            const data = cell.getRow().getData()
            return data?.last_note?.note_text
          }
        },
        {
          title: 'Assigned To',
          field: 'task_assignment',
          cssClass: 'is-link',
          width: 200,
          formatter: cell => {
            const value = cell.getValue()
            const employee = this.employees.find(emp => emp.Employee_Code == value)
            return employee ? employee.Employee_Name : value
          }
        },
        {
          title: 'Job/WO Number',
          field: 'job_wo_number',
          cssClass: 'is-link',
          width: 150,
          formatter: cell => {
            const data = cell.getRow().getData()
            return data.job_number ? `Job ${data.job_number}` : data.wo_number ? `WO ${data.wo_number}` : ''
          }
        },
        {
          title: 'Job/WO Description',
          field: 'job_wo_description',
          cssClass: 'is-link',
          width: 200,
          formatter: cell => {
            const data = cell.getRow().getData()
            return data.job_number ? `${data.Job_Description}` : data.wo_number ? `WO ${data.Summary_Description}` : ''
          }
        },
        {
          title: 'Customer',
          field: 'customer',
          cssClass: 'is-link',
          width: 150,
          formatter: cell => {
            const row = cell.getRow().getData()
            const customerCode = row.Customer_Code || row.Bill_Customer_Code
            const cust = this.customers.find(itm => itm.Customer_Code == customerCode)
            return cust ? cust.Name : customerCode
          }
        },
        {
          title: 'Billing Status',
          field: 'status',
          cssClass: 'is-link',
          visible: false,
          width: 120,
          formatter: cell => {
            const value = cell.getValue()
            const statusOption = this.statusOptions.find(option => option.value === value)
            return statusOption ? statusOption.name : value
          }
        },
        {
          title: 'Create Date',
          field: 'date_create',
          cssClass: 'is-link',
          width: 150,
          formatter: cell => {
            const value = cell.getValue()
            return this.formatDate(value)
          }
        },
        {
          title: 'Days Old',
          field: 'days_old',
          cssClass: 'is-link',
          width: 150
        },
        {
          title: 'Days Overdue',
          field: 'days_overdue',
          cssClass: 'is-link',
          width: 150,
          formatter: cell => {
            const value = cell.getValue()
            return value ? `${value} days` : '—'
          }
        },
        {
          title: 'Due Date',
          field: 'date_due',
          cssClass: 'is-link',
          width: 150,
          visible: false
        },
        {
          title: 'Completed On',
          field: 'date_closed',
          cssClass: 'is-link',
          width: 150,
          formatter: cell => {
            const value = cell.getValue()
            return value ? this.formatDate(value) : '—'
          }
        }
      ]
    },

    filteredTasks() {
      let list = Array.isArray(this.data) ? JSON.parse(JSON.stringify(this.data)) : []
      return list.map(itm => ({
        ...itm,
        days_old: this.getDaysOld(itm.date_create),
        days_overdue:
          itm.date_due && itm.status === 'O'
            ? moment(new Date())
                .startOf('day')
                .diff(moment(itm.date_due * 1000).startOf('day'), 'days')
            : ''
      }))
    }
  },

  methods: {
    rowFormatter(row) {
      const data = row.getData()
      let days = this.getDaysOld(data.date_create, false)
      const rowEl = row.getElement()

      // Remove existing color classes
      rowEl.classList.remove('danger-color', 'warning-color', 'success-color')

      days = parseInt(days)

      if (data.status === 'O') {
        if (days >= 14) {
          rowEl.classList.add('danger-color')
        } else if (days >= 7) {
          rowEl.classList.add('warning-color')
        } else {
          rowEl.classList.add('success-color')
        }
      }
    },

    colClick(obj) {
      const col = ((obj || {}).cell || {}).field || null
      const phaseCode = ((obj || {}).row || {}).Phase_Code || null
      const costType = ((obj || {}).row || {}).Cost_Type || null
      const id = ((obj || {}).row || {}).id || null
      const parent_id = ((obj || {}).row || {}).parent_id || null

      // if (col === 'parent_task_type' || col === 'task_type' || col === 'billing_instructions') {
      this.$router.push({ path: `/app/tasks/subtask/${id}` })
      this.openTask(parent_id, id)
      // }
    },

    openTask(id, subTaskId) {
      this.$Modal({
        parent: this,
        name: 'BillingTask', // used for closing specific modal programmatically
        size: 'xxl', // sm, md, lg, xl
        title: '',
        contentClasses: 'smoke-bg',
        hideClose: true,
        component: () => import('src/components/Dashboard/Billing/BillingTask.vue'),
        props: {
          id,
          subTaskId
        }
      })
    },

    getTasks() {
      // if (!this.WO_Number && !this.Job_Number) {
      //   this.$snack.open('Job or WO must be selected to fetch', 'error')
      //   return
      // }

      const task_assignment = this.isAdmin ? this.viewAsEmployeeId : this.userAuthedData.eid

      const params = {
        action: 'get_all_billing_tasks',
        filters: {
          sub_task_type: this.subTaskType,
          task_type: this.billingTaskStatus,
          task_assignment,
          status: this.taskStatus,
          approval_status: this.approvalStatus,
          costGroupCode: this.costGroupCode
        }
      }

      return new Promise((resolve, reject) => {
        this.$bus.$emit('setWaiting', {
          name: params.action,
          message: 'Getting Tasks'
        })
        appFuncs
          .shRequest(params)
          .then(data => {
            this.data = data
            resolve()
          })
          .catch(err => {
            console.log(err)
            this.$snack.open(err.message || 'Problem getting tasks', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      })
    },

    getTask(subTaskId) {
      return new Promise((resolve, reject) => {
        const params = {
          action: 'get_billing_task',
          id: subTaskId
        }

        this.$bus.$emit('setWaiting', {
          name: params.action,
          message: 'Getting Task'
        })
        appFuncs
          .shRequest(params)
          .then(data => {
            this.openTask(data.id, subTaskId)
            resolve()
          })
          .catch(err => {
            console.log(err)
            this.$snack.open(err.message || 'Problem getting task', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      })
    },

    addTask() {
      return
      this.$bus.$emit('modalClose', 'BillingTaskAdd')
      this.$bus.$emit('modalClose', 'BillingTasks')
      this.$Modal({
        parent: this,
        name: 'BillingTaskAdd', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        title: `Add Billing Task`,
        component: () => import('src/components/Dashboard/Billing/BillingTaskAdd.vue'),
        props: {
          WO_Number: this.WO_Number,
          Job_Number: this.Job_Number
        }
      })
    },

    handleModalClose(id) {
      const { taskType, taskId } = this.$route.params
      if (id === 'BillingTask' && taskType && taskId) this.$router.push({ path: '/app/tasks' })
    }
  },

  async mounted() {
    this.$store.dispatch('getEmployees')
    this.$store.dispatch('getCustomers')
    this.$store.dispatch('getEmCostGroups')

    if (!this.viewAsEmployeeId) {
      this.$store.commit('viewAsEmployeeId', this.userAuthedData.eid)
    }

    if (this.$route.query.show === 'feedback-requested') {
      this.subTaskType = 'FR'
      this.$router.replace({ query: {} })
    } else if (this.$route.query.show === 'missing-po') {
      this.subTaskType = 'MPO'
      this.$router.replace({ query: {} })
    } else if (this.$route.query.show === 'files-requested') {
      this.subTaskType = 'RF'
      this.$router.replace({ query: {} })
    } else if (this.$route.query.show === 'upload-draw-approval') {
      this.subTaskType = 'UDA'
      this.$router.replace({ query: {} })
    } else if (this.$route.query.show === 'sign-off-missing') {
      this.subTaskType = 'SOM'
      this.$router.replace({ query: {} })
    } else if (this.$route.query.show === 'waiting-pm-approval') {
      this.subTaskType = 'IA'
      this.approvalStatus = 'waiting-pm-approval'
      this.$router.replace({ query: {} })
    }

    await this.getTasks()

    const { taskType, taskId } = this.$route.params

    if (taskType && taskId) {
      const useTaskType = taskType === 'subtask' ? 'subtask' : 'main'
      const subTask = useTaskType === 'subtask' ? taskId : null
      const mainTask = useTaskType === 'main' ? taskId : null
      this.openTask(mainTask, subTask)
    }

    this.$bus.$on('modalClose', this.handleModalClose)
    this.$bus.$on('subTaskUpdated', this.getTasks)
  },

  beforeDestroy() {
    this.$bus.$off('modalClose', this.handleModalClose)
    this.$bus.$off('subTaskUpdated', this.getTasks)
  },

  watch: {
    viewAsEmployeeId: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getTasks()
        }
      }
    }
  }
}
</script>
