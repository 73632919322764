
<div>
  <div class="max-width-xlarge">
    <div class="row">
      <div class="col-md-3 col-lg-2" v-show="isAdmin">
        <set-view-as-employee />
      </div>
      <div class="col-md-3 col-lg-2">
        <select-department-as-employee :value="selectedDepartment" :is-show-label="true" :employee-id="viewAsEmployeeId" @selectItem="clearViewAsEmployee" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <widget-projects-by-department v-if="showWidgets" />
        <widget-quotes-by-department v-if="showWidgets" />
        <widget-billings-by-department v-if="showWidgets" />
        <widget-hours-tracker :employee-id="viewAsEmployeeId" :show-department-selector="false" />
      </div>
    </div>

    <widget-my-missing-pos />
    <!-- <WidgetPmApprovals v-if="isPM || isAdmin" @filter-pm="routeTimeApprovals" /> -->

    <widget-my-jobs />
    <widget-jobs-w-os-report-sent />
    <widget-my-wos />
    <widget-my-quotes v-if="isPM || isAdmin" :show-statuses="['Quote in Progress', 'Re-Opened - Not Sent']" />
    <widget-my-quotes v-if="isPM || isAdmin" :show-statuses="['Quote in Progress', 'Re-Opened - Not Sent']" :filter="'overdue'" />
    <!-- <widget-my-wo-status-notes /> -->
  </div>
</div>
