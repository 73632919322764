
<div>
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-3">
      <button class="btn" @click.exact="addItem()" @click.alt="addItem('quote2')">New Quote</button>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-7 pull-right">
      <div class="legend">
        <div @click="changeStatusFilter('On Track', 'Quote in Progress', 'Quote in Progress')"><span class="blue" />On Track</div>
        <div @click="changeStatusFilter('Approaching', 'Quote in Progress', 'Quote in Progress')"><span class="yellow" />Approaching</div>
        <div @click="changeStatusFilter('Overdue Required', 'Quote in Progress', 'Quote in Progress')"><span class="orange" />Overdue Required</div>
        <div @click="changeStatusFilter('Overdue Decision', '', 'Sent and Pending')"><span class="purple" />Overdue Decision</div>
        <div @click="changeStatusFilter('', '', 'All', 'Won')"><span class="green" />Won</div>
        <div @click="changeStatusFilter('', '', 'All', 'Lost')"><span class="red" />Lost</div>
      </div>
      <!-- <select-field
        :options="fiscalYears"
        v-model="fiscalYear"
        :option-display-keys="['name']"
        :option-val="'val'"
      /> -->
    </div>
  </div>

  <br />
  <!--
    <widget-my-quotes :fiscal-year="fiscalYear" />
    <widget-my-sales-history :fiscal-year="fiscalYear" />
  <br><br> -->

  <div class="tab-section white">
    <div class="">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab == 'Quote in Progress' }]">
          <a href="#" @click="changeTab('Quote in Progress', 'Quote in Progress')"> Quote in Progress </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Sent and Pending' }]">
          <a href="#" @click="changeTab('Sent and Pending', 'Sent and Pending')"> Sent and Pending</a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'All' }]">
          <a href="#" @click="changeTab('All')"> All Quotes </a>
        </li>

        <li role="presentation" :class="[{ active: tab == 'unassigned' }]">
          <a href="#" @click="changeTab('unassigned', 'Unassigned')"> Unassigned Quotes </a>
        </li>
      </ul>

      <div :class="['card', 'quote-filters', { Main: tab == 'Quote in Progress' }]">
        <div class="content">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Fiscal Year</label>
              <select-field :options="fiscalYears" v-model="fiscalYear" :option-display-keys="['name']" :option-val="'val'" :allow-clear="true" />
            </div>
            <div v-if="tab === 'All'" class="col-sm-12 col-md-6 col-lg-3">
              <label>Status</label>
              <select-field :options="quoteStatusOptions" v-model="status" :option-display-keys="['name']" :option-val="'name'" :allow-clear="true" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Customer</label>
              <select-field :options="customers" :option-display-keys="['Name']" :option-val="'Customer_Code'" v-model="customer" :allow-clear="true" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Due Status</label>
              <select-field
                :options="[{ name: 'Approaching' }, { name: 'Overdue Required' }, { name: 'Overdue Decision' }, { name: 'On Track' }]"
                v-model="showDueStatus"
                :option-display-keys="['name']"
                :option-val="'name'"
                :allow-clear="true"
                @selectItem="doSearch(false)"
              />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Lead Estimator</label>
              <select-field
                :options="quoteEmployeeList"
                :option-display-keys="['Employee_Name']"
                :option-val="'Employee_Code'"
                v-model="quotedBy"
                :allow-clear="true"
                :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
              />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Sales Lead</label>
              <select-field
                :options="quoteEmployeeList"
                :option-display-keys="['Employee_Name']"
                :option-val="'Employee_Code'"
                v-model="salesPerson"
                :allow-clear="true"
                :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
              />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Captain</label>
              <select-field
                :options="quoteEmployeeList"
                :option-display-keys="['Employee_Name']"
                :option-val="'Employee_Code'"
                v-model="captain"
                :allow-clear="true"
                :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                @selectItem="doSearch(false)"
              />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Division</label>
              <select-field :options="divisionCostGroupOptions" :option-display-keys="['name']" :option-val="'value'" v-model="cost_center" :allow-clear="true" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Any</label>
              <select-field-multi
                :options="quoteEmployeeList"
                :option-display-keys="['Employee_Name']"
                :option-val="'Employee_Code'"
                v-model="employeeAny"
                :allow-clear="true"
                :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                :show-remove-warning="false"
                @selectItem="doSearch(false)"
              />
            </div>
          </div>
          <br />
          <div class="search-input-group">
            <input type="text" :class="['form-control', 'search-all', { 'not-empty': searchKeyword }]" v-model="searchKeyword" placeholder="Search quotes..." @keyup.enter="() => doSearch(false)" />
            <div class="search-buttons">
              <button class="btn btn-sm btn-blue" @click="() => doSearch(false)">Search Filtered</button>
              <button class="btn btn-sm" @click="() => clearFilters()">Search All</button>
              <button class="btn btn-sm btn-danger" @click="() => clearFilters(true)">Reset</button>
            </div>
          </div>
          <bh-table :table-settings="tableSettings1" @Xedit-item-obj="editItem" @colClick="colClick" />
        </div>
      </div>
    </div>

    <waiting-spinner />
  </div>
</div>
