<template>
  <div class="max-width-xlarge">
    <div class="tab-section white">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab === 'Main' }]" v-if="jobId">
          <a href="#" @click="tab = 'Main'"> Main </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Job Extras' }]" v-if="jobId">
          <a href="#" @click="tab = 'Job Extras'">
            <span v-if="job.Price_Method_Code === 'F'">Job Extras</span>
            <span v-else>T&amp;M Sign Off</span>
          </a>
        </li>
        <li
          role="presentation"
          v-if="jobId"
          :class="[
            { active: tab === 'Information' },
            {
              'requires-item': missingSignoffRequirements.indexOf('Scope of Work Notes') !== -1 || missingSignoffRequirements.indexOf('Work Completed Notes') !== -1
            }
          ]"
        >
          <a href="#" @click="tab = 'Information'"> Info </a>
        </li>
        <li role="presentation" :class="[{ active: tab === 'Phases' }]" v-if="jobId">
          <a href="#" @click="tab = 'Phases'"> Job Analysis </a>
        </li>
        <li role="presentation" :class="[{ active: tab === 'Files' }]" v-if="jobId && isPM">
          <a href="#" @click="tab = 'Files'"> Doc's and Photos </a>
        </li>
        <li role="presentation" :class="[{ active: tab === 'Labor' }]" v-if="jobId">
          <a href="#" @click="tab = 'Labor'"> Labor </a>
        </li>
        <li role="presentation" :class="[{ active: tab === 'Contacts' }]" v-if="jobId">
          <a href="#" @click="tab = 'Contacts'"> Other Job Contacts </a>
        </li>
        <li role="presentation" :class="[{ active: tab === 'Purchasing' }]" v-if="jobId">
          <a href="#" @click="tab = 'Purchasing'"> POs </a>
        </li>
        <!-- <li role="presentation" :class="[{active: tab === 'Costing'}]">
          <a href="#" @click="tab = 'Costing'">
            Costing
          </a>
        </li> -->
        <li role="presentation" :class="[{ active: tab === 'PM' }]" v-if="jobId">
          <a href="#" @click="tab = 'PM'"> PM </a>
        </li>
      </ul>

      <div :class="['card', tab]">
        <div class="content max-width-large">
          <div v-if="tab == 'Main'">
            <div class="row">
              <div class="col-sm-12">
                <alert
                  :alert="{
                    show: !this.jobPhases.length && fetchedJobPhases && jobId,
                    message: 'Please add phases to complete job setup',
                    state: 'warning',
                    dismiss: 'none'
                  }"
                />
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="detail-item form-group" v-if="job.Job_Number">
                  <label>Job Number</label>

                  <!-- <select-field
                    v-model="jobId"
                    :options="jobAssemble"
                    :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
                    :option-val="'Job_Number'"
                    :empty-option-text="'No items.'"
                    @selectItem="setGetJob"
                  /> -->

                  <div class="job-number-wrapper is-link" @click="selectJob">
                    <h4>{{ job.Job_Number }}</h4>
                    <i class="ti-angle-down"></i>
                  </div>
                </div>

                <div class="detail-item form-group">
                  <label for="job_number">Quote Number</label>&nbsp;&nbsp;<span v-if="quoteLink" class="ti ti-new-window is-link" @click="openQuote(true)" />
                  <input type="text" class="form-control" :value="quoteNumber" :readonly="true" :disabled="!editable" @click="selectJobQuoteNumber" />
                  <p v-if="this.job.job_quote_url" style="padding: 5px 0" class="is-link underlined" @click="openQuotePdf">View Quote PDF</p>
                </div>

                <div class="detail-item form-group">
                  <label>Job Name</label><span v-if="editable" class="required-star">*</span>
                  <input type="text" class="form-control" :disabled="!editable" :value="decode(job.Job_Description)" @input="job.Job_Description = $event.target.value" />
                </div>

                <!-- <div class="detail-item form-group">
                  <label>Cost Center</label><span v-if="editable" class="required-star">*</span>
                  <select-field :options="costCenters" :option-display-keys="['name']" :option-val="'value'" v-model="job.Cost_Center" :disabled="(!editable || jobId) ? true : false" />
                </div> -->

                <div class="form-group">
                  <label>Division</label><span class="required-star">*</span>
                  <select-field
                    :options="divisionCostGroupOptions"
                    :option-display-keys="['name']"
                    :option-val="'value'"
                    v-model="job.division_cost_group"
                    :disabled="!editable"
                    :allow-clear="true"
                    :empty-option-text="'None'"
                    @selectItem="job.Cost_Center = ''"
                  />
                </div>

                <div class="form-group">
                  <label>Department</label><span class="required-star">*</span>
                  <select-field
                    :options="costCentersByDivision"
                    :option-display-keys="['Name']"
                    :option-val="'Code'"
                    v-model="job.Cost_Center"
                    :disabled="!editable"
                    :allow-clear="true"
                    :empty-option-text="'None'"
                  />
                </div>

                <div class="detail-item form-group">
                  <label>Customer</label><span class="required-star">*</span>
                  <select-field
                    :options="customers"
                    :option-display-keys="['Name']"
                    :option-val="'Customer_Code'"
                    v-model="job.Customer_Code"
                    :empty-option-text="'No Customers Found'"
                    :filter-on-key-val="{ key: 'Status', val: 'Active' }"
                    :disabled="(jobId || setFromQuote) && job.Customer_Code ? true : false"
                    :fake-disabled="false"
                  />
                  <br />
                  <button :class="['btn', 'btn-sm', 'btn-orange']" @click="openCustomerWarnings" v-html="'Warnings'" v-if="show_warnings" />
                </div>

                <div class="detail-item form-group">
                  <label>Site</label><span class="required-star">*</span>
                  <select-field
                    :options="filteredSites"
                    :option-display-keys="['Name', 'site_address']"
                    :option-val="'Ship_To_ID'"
                    v-model="job.WO_Site"
                    :empty-option-text="'No sites found'"
                    :filter-on-key-val="{ key: 'Status', val: 'A' }"
                    :disabled="jobId && loadedJob.WO_Site ? true : false"
                  />
                </div>

                <div v-if="selectedSite" class="detail-item form-group">
                  <label>Site Location</label>
                  <p>
                    <a v-if="selectedSite" target="_blank" :href="'https://maps.google.com/?q=' + selectedSite.site_address + ' ' + selectedSite.site_city + '' + selectedSite.Ship_To_Zip_Code">
                      {{ selectedSite.site_address }}, {{ selectedSite.site_city }}, {{ selectedSite.Ship_To_Zip_Code }}</a
                    >
                  </p>
                </div>
                <div class="detail-item form-group">
                  <label>PO Number</label
                  ><!-- <span v-if="editable" class="required-star">*</span> -->
                  <br />
                  <button v-if="jobId" class="btn btn-sm" @click="requestPO">Request Via Email</button>
                  <input :disabled="!editable" type="text" :class="['form-control', { invalid: jobId && !job.po_number }]" v-model="job.po_number" />
                  <div v-if="editable" style="padding-top: 10px">
                    <!-- <file-upload ref="poUploadComponent" :wait-for-trigger="jobId ? false : true" label="" :path="'job-uploads/' + jobId + '/'" :subdir="'po'" :zip-depth="-1" name="PO" :simple-upload="false" :upload-data="uploadData" @fileAdded="poFileAdded" /> -->
                    <job-po-upload ref="jobPOUpload" :job-id="jobId || ''" />
                  </div>
                </div>

                <div class="detail-item form-group">
                  <label>Price Type</label><span v-if="editable" class="required-star">*</span>
                  <select-field
                    :options="globalOptions['payment_price_type']"
                    :option-display-keys="['name']"
                    :option-separator="' '"
                    :option-val="'value'"
                    v-model="job.Price_Method_Code"
                    :disabled="!editable || jobId"
                    :allow-clear="true"
                  />
                </div>

                <div class="detail-item form-group" v-if="isPM">
                  <label v-if="job.Price_Method_Code !== 'T'">Contract Price</label>
                  <label v-else>T&amp;M Price</label>
                  <span v-if="editable" class="required-star">*</span>
                  <!-- <input :disabled="!editable || jobId" type="text" :class="['form-control']" v-model="job.Original_Contract" /> -->
                  <vue-numeric
                    currency="$"
                    separator=","
                    :class="['form-control']"
                    :precision="2"
                    :read-only-class="'form-control readonly'"
                    v-model="job.Original_Contract"
                    :disabled="!editable || jobId || (setFromQuote && job.Original_Contract) ? true : false"
                  />
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <div class="detail-item form-group">
                  <customer-contact-select-field
                    :customer-code="job.Customer_Code"
                    :label="'Requested By'"
                    :default="job.Owner_Name"
                    v-model="job.Owner_Name"
                    v-show="editable || allowRequestedByChange"
                    :required="true"
                    :output-name="true"
                    @customerContactSelect="setOwner"
                  />
                  <div v-if="!editable && !allowRequestedByChange">
                    <label>Requested By</label>
                    <input type="text" class="fake-readonly form-control" :value="job.Owner_Name" readonly @click="requestedByPreChange()" />
                  </div>
                </div>

                <div class="detail-item form-group">
                  <label>Other Job Contacts</label>
                  <select-field-multi
                    :options="filteredContacts"
                    :option-display-keys="['Name']"
                    :option-val="'Id'"
                    v-model="job.job_contacts"
                    :disabled="!editable"
                    :allow-clear="true"
                    @addItem="addJobContacts"
                  />
                </div>

                <div class="detail-item form-group">
                  <label>Create Date</label><span v-if="editable" class="required-star">*</span>
                  <date-picker disabled v-model="job.Create_Date" :pre-filled="true" />
                </div>

                <div class="detail-item form-group">
                  <label v-if="job.project_stage === 'not-started'">Expected Start Date</label>
                  <label v-else>Start Date</label>
                  <span v-if="editable" class="required-star">*</span>
                  <input
                    v-if="job.id && job.Est_Start_Date"
                    :value="formatDate(job.Est_Start_Date)"
                    class="form-control fake-readonly fake-disabled"
                    readonly
                    @click="editRequiredDate"
                    :disabled="!editable"
                  />
                  <date-picker v-else :disabled="!editable" v-model="job.Est_Start_Date" :pre-filled="false" />
                </div>

                <div class="detail-item form-group">
                  <label>Expected Finished Date</label><span v-if="editable" class="required-star">*</span>
                  <input
                    v-if="job.id && job.Est_Complete_Date"
                    :value="formatDate(job.Est_Complete_Date)"
                    class="form-control fake-readonly fake-disabled"
                    readonly
                    @click="editRequiredDate"
                    :disabled="!editable"
                  />
                  <date-picker v-else :disabled="!editable" v-model="job.Est_Complete_Date" :pre-filled="false" />
                </div>

                <div class="detail-item form-group">
                  <label>Project Lead</label><span v-if="editable" class="required-star">*</span>
                  <select-field
                    :options="employeesWithRoleFlags"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="job.Project_Manager"
                    :disabled="!editable"
                    :filter-on-key-val="{ key: 'hasPMRole', val: 'yes' }"
                  />
                </div>

                <div class="detail-item form-group">
                  <label>Sales Person</label>
                  <select-field
                    :options="pmUsers"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="job.sales_person"
                    :disabled="!editable"
                    :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  />
                </div>

                <div class="detail-item form-group">
                  <label>Captain</label><span v-if="allowCaptainEdit" class="required-star">*</span>
                  <select-field
                    :options="employeesWithRoleFlags"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="job.Superintendent"
                    :disabled="!allowCaptainEdit"
                    :filter-on-key-val="{ key: 'hasCaptainRole', val: 'yes' }"
                  />
                </div>

                <div class="detail-item form-group">
                  <label>Estimator</label><span v-if="editable && !(quoteNumber && job.Estimator)" class="required-star">*</span>
                  <select-field
                    :options="pmUsers"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="job.Estimator"
                    :disabled="!editable && quoteNumber && job.Estimator"
                    :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  />
                </div>

                <div class="detail-item form-group">
                  <label>Techs</label>
                  <select-field-multi
                    :options="employees"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="job.techs"
                    :disabled="!editable"
                    :placeholder-text="'Add Tech'"
                    :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                    :label="'Techs'"
                  />
                </div>

                <div class="detail-item form-group">
                  <span class="inline-checkbox">
                    <checkbox2 v-model="job.progress_invoice" true-val="yes" false-val="no" :disabled="!editable || !canEditDrawRequest">Draw Request - Progress Invoice</checkbox2>
                  </span>
                </div>

                <div class="detail-item form-group">
                  <label>Billing Agent</label>
                  <select-field
                    :options="billingEmployees"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="job.billing_employee"
                    :disabled="!editable"
                    :placeholder-text="'Select'"
                    :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                    :label="'Techs'"
                  />
                </div>

                <div class="detail-item form-group">
                  <label>Deposit Amount</label>
                  <select-field :options="globalOptions['deposit_amount']" :option-display-keys="['name']" :option-separator="' '" :option-val="'id'" :value="job.deposit_percent" :disabled="true" />
                </div>
              </div>
            </div>

            <br />

            <!-- <button v-if="!jobId" :class="['btn']" @click="addJob" v-html="'Add Job'" /> -->
            <button v-if="jobId && (editable || allowRequestedByChange) && isPM" :class="['btn', { pulse: hasUnsaved }, { 'btn-primary': hasUnsaved }]" @click="updateJob()" v-html="'Update'" />
            <button v-if="!jobId && isPM" class="btn" @click="addJob">Add Job</button>
          </div>

          <div v-else-if="tab == 'Job Extras'">
            <job-extras :job="job" :job-number="parseInt(jobId)" :is-locked="isLocked" />
            <!-- readonly is set by isLocked by parent -->
          </div>

          <div v-else-if="tab == 'Information'">
            <job-info :job-id="jobId" :job="job" :read-only="isLocked" :missing-signoff-requirements="missingSignoffRequirements" :quote-number="quoteNumber" />
          </div>

          <div v-else-if="tab == 'Phases'" :class="'phases-tab'">
            <job-phases-numbers-details :job-id="jobId" :job="job" :is-locked="isLocked" :show-details-tab="true" :show-restricted="isJobPMCaptain" />

            <!-- <job-phases-summary :job-id="jobId" :job="job" :phase-data="phaseData" :is-locked="isLocked" /> -->

            <!-- <div class="tab-section outline white">
              <ul class="nav nav-tabs">
                <li role="presentation" :class="[{active: phaseTab == 'Details'}]">
                  <a href="#" @click="phaseTab = 'Details'">
                    Details
                  </a>
                </li>
                <li role="presentation" :class="[{active: phaseTab == 'Summary'}]">
                  <a href="#" @click="phaseTab = 'Summary'">
                    Summary
                  </a>
                </li>
              </ul>
              <div :class="[{'first-active':phaseTab == 'Summary'}, 'tab', phaseTab, 'tab-content']">
                <div v-if="phaseTab === 'Details'">
                  <job-phases-details :job-id="jobId" :job="job" :phase-data="phaseData" :is-locked="isLocked" />
                </div>
                <div v-if="phaseTab === 'Summary'">
                  <job-phases-summary :job-id="jobId" :job="job" :phase-data="phaseData" :is-locked="isLocked" />
                </div>
              </div>
            </div> -->
          </div>

          <div v-else-if="tab == 'Files'">
            <job-files :job-id="jobId" />
          </div>

          <div v-else-if="tab == 'Purchasing'" :class="['tab', 'purchasing', 'tab-purchasing', tab]">
            <job-pos :itm-code="jobId" :job="job" :is-locked="isLocked" />
          </div>

          <div v-else-if="tab == 'Contacts'" :class="['tab', 'contacts']">
            <job-contacts :jobid="parseInt(jobId)" />
            <!--            <bh-table-->
            <!--              :table-settings="contactsTableSettings"-->
            <!--              @edit-item="editContact"-->
            <!--            />-->
          </div>

          <div v-else-if="tab == 'Labor'" :class="['tab', 'labor', 'tab-labor', tab]">
            <job-wo-labor type="Job" :itm-id="parseInt(jobId)" :is-locked="Boolean(isLocked)" :allow-download="true" />
          </div>

          <div v-else-if="tab == 'PM'" :class="['tab', 'labor', 'tab-labor', tab]">
            <job-wip-details :input-job-id="parseInt(jobId)" :job-data="job" :is-locked="isLocked" ref="jobwipdetails" />
          </div>
        </div>
      </div>

      <a
        v-if="jobSignOff && (!isSigned || isAdmin)"
        :disabled="missingSignoffRequirements.length || job.status !== 'A' ? 'disabled' : false"
        class="btn"
        v-html="'Job Sign Off'"
        :href="$store.getters.urls.frontend + '#/job-signoff/' + this.jobSignOff"
        target="_blank"
      />

      <a class="btn" v-if="ticketUrl" :href="ticketUrl" target="'_blank" v-html="'Customer Ticket'" />
      <a v-if="isPM && isLocked" class="btn" v-html="'Unlock'" @click="unLockJob" />
      <!--
      <a
        v-if="isPM"
        class="btn"
        v-html="'Phases'"
        @click="jobPhases"
      /> -->

      <!-- <p v-if="isLocked" class="text-left">
        <i>Job locked (Stage not TBS or In Progress)</i>
      </p> -->

      <p v-if="job.status === 'I'">
        <i><strong>Job is marked Inactive, if this is a new job it may be awaiting approval.</strong></i>
      </p>
      <p v-else-if="missingSignoffRequirements.length">
        <i
          >Sign off requirements not yet met: <strong class="text-danger">{{ missingSignoffRequirements.join(', ') }}</strong></i
        >
      </p>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import JobFiles from 'components/Dashboard/JobWo/JobFiles3'
import NavTabs from 'mixins/navTabs'
import JobFuncs from 'mixins/JobFuncs'
import BhTable from 'components/UIComponents/BhTable'

import sanitizeHtml from 'sanitize-html'
import JobInfo from 'components/Dashboard/JobWo/JobInfo'
import CustomerContactSelectField from 'components/UIComponents/CustomerContactSelectField'
import JobPos from 'components/Dashboard/JobWo/JobPOs'
import JobWoLabor from 'components/Dashboard/JobWo/JobWOLabor'
import CustomerContactsAddEdit from 'src/components/Dashboard/Contacts/CustomerContactsAddEdit.vue'
import JobExtras from 'components/Dashboard/JobWo/JobExtras'
import SelectField from 'components/UIComponents/SelectField'
import SelectFieldMulti from 'components/UIComponents/SelectFieldMulti'
import JobWipDetails from 'components/Dashboard/JobWo/JobWIPDetails.vue'
import { diff, detailedDiff } from 'deep-object-diff'
import DatePicker from 'components/UIComponents/DatePicker'
import JobPhasesNumbersDetails from 'components/Dashboard/JobWo/jobPhasesNumbersDetails.vue'
import VueNumeric from 'vue-numeric'
import JobPoUpload from 'components/Dashboard/JobWo/JobPOUpload.vue'
import ItemComments from 'components/GeneralViews/ItemCommentsWYSIWYG'
import Alert from 'components/UIComponents/Alert'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import store from 'store'
import billingFuncs from 'mixins/billingFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox'
import JobContacts from 'components/Dashboard/JobWo/JobContacts'
import { showConfirmDialog } from 'src/confirmDialog'
import dateFormat from 'dateformat'

import { isAfter, isBefore, isSameDay, parseISO } from 'date-fns'

export default {
  data() {
    return {
      tab: 'Main',
      phaseTab: 'Details',
      jobId: '',
      jobPMDetails: {},
      unsavedInterval: '',
      jobSignOff: '',
      requestedById: null,
      missingSignoffRequirements: [],
      // declare props to help with template seeing updates
      jobSummaryDetails: {},
      jobPhases: [],
      allowRequestedByChange: false,
      hasUnsaved: false,
      fetchedJobPhases: false,
      job: {
        Customer_Name: '',
        Address_1: '',
        City: '',
        Customer_Code: '',
        WO_Site: '',
        Job_Description: '',
        Project_Manager: '',
        Customer_PO_Number: '',
        Owner_Name: '',
        State: '',
        Zip_Code: '',
        Project_Manager: '',
        Superintendent: '',
        Estimator: '',
        techs: [],
        division_cost_group: '',
        Cost_Center: '',
        po_number: '',
        sales_person: '',
        Price_Method_Code: '',
        Original_Contract: '',
        Create_Date: '',
        Est_Start_Date: '',
        Est_Complete_Date: '',
        region: '',
        sub_cost_center: '',
        billing_employee: '',
        progress_invoice: '',
        invoice_contacts: [],
        job_contacts: [],
        project_stage: 'not-started',
        deposit_percent: ''
      },
      loadedJob: {
        Customer_Name: '',
        Address_1: '',
        City: '',
        Customer_Code: '',
        WO_Site: '',
        Job_Description: '',
        Project_Manager: '',
        Customer_PO_Number: '',
        Owner_Name: '',
        State: '',
        Zip_Code: '',
        Project_Manager: '',
        Superintendent: '',
        Estimator: '',
        division_cost_group: '',
        Cost_Center: '',
        po_number: '',
        sales_person: '',
        Price_Method_Code: '',
        Original_Contract: '',
        Create_Date: '',
        Est_Start_Date: '',
        Est_Complete_Date: '',
        techs: [],
        region: '',
        sub_cost_center: '',
        billing_employee: '',
        deposit_percent: '',
        progress_invoice: '',
        invoice_contacts: [],
        job_contacts: []
      },
      phaseData: null,
      customerCode: null,
      test: '',
      quote: 0,
      quoteData: {},
      revision: 0,
      quoteNumber: '',
      superintendentName: '',
      estimatorName: '',
      editNotes: [
        {
          key: 'workDescription',
          category: 'Work Description',
          originalText: '',
          newText: '',
          editing: false
        },
        {
          key: 'internal',
          category: 'Internal (SES Only)',
          originalText: '',
          newText: '',
          editing: false
        },
        {
          key: 'otherDetails',
          category: 'Other Details',
          originalText: '',
          newText: '',
          editing: false
        }
      ],
      contactsTableSettings: {
        fields: {
          Name: {
            name: 'Name'
          },
          Title: {
            name: 'Title'
          },
          Phone: {
            name: 'Direct Phone'
          },
          Email: {
            name: 'Email'
          },
          // direct_phone: {
          //   name:'Direct Phone'
          // },
          Phone_Extension__c: {
            name: 'Direct Phone Ext'
          },
          MobilePhone: {
            name: 'Mobile Phone'
          }
        },
        tableData: [],
        hideSearch: true
      },
      globalOptions: {},
      setFromQuote: false,
      show_warnings: false,
      trackedPONumber: ''
    }
  },

  components: {
    BhTable,
    JobInfo,
    JobFiles,
    CustomerContactSelectField,
    JobPos,
    JobExtras,
    JobWoLabor,
    SelectField,
    JobWipDetails,
    DatePicker,
    JobPhasesNumbersDetails,
    VueNumeric,
    JobPoUpload,
    SelectFieldMulti,
    Alert,
    WaitingSpinner,
    Checkbox2,
    JobContacts
  },

  mixins: [NavTabs, JobFuncs, billingFuncs],

  props: {
    addSetQuoteNumber: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters(['customerContacts', 'employees', 'jobAssemble', 'jobUdfs', 'woSites', 'customers', 'emCostGroups', 'emCostGroupsWithCostCenters']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isEditor() {
      return this.$store.getters.userAuthedData.user_role.indexOf('editor') !== -1
    },

    isEditorf() {
      return this.isEditor && this.$store.getters.userAuthedData.user_role.indexOf('F') !== -1
    },

    isPMq() {
      return this.isPM && this.userAuthedData.user_role.indexOf('Q') !== -1
    },

    userID() {
      return this.$store.getters.userAuthedData.eid
    },

    ticketUrl() {
      if (this.job.ticket_link) {
        return this.$store.getters.urls.frontend + '#/customer-ticket/job/' + this.job.ticket_link
      } else {
        return ''
      }
    },

    isJobPM() {
      if (this.userID == this.job.Project_Manager) {
        return true
      }
      return false
    },

    isPMp() {
      return this.isPM && this.userAuthedData.user_role.indexOf('P') !== -1
    },

    isCaptain() {
      const employee = this.employees.find(itm => {
        return itm.Employee_Code == this.userID
      })
      if (employee && Array.isArray(employee.job_roles)) {
        return employee.job_roles.indexOf('captain') !== -1
      }
      return false
    },

    isLocked() {
      const unlockedStatusCodes = [1, 2]
      return this.job.Stage && unlockedStatusCodes.indexOf(this.job.Stage) === -1
    },

    isSigned() {
      const signedCodes = [13, 4]
      return this.job.Stage && signedCodes.includes(this.job.Stage)
    },

    editable() {
      if (!this.isLocked) {
        return true
      }
      return false
    },

    pmUsers() {
      let emps = this.employees.filter(itm => {
        if (Array.isArray(itm.roles)) {
          return itm.roles.indexOf('pm') !== -1
        }
      })
      return emps
    },

    employeesWithRoleFlags() {
      return this.employees
        .filter(itm => itm.Employment_Status === 'A')
        .map(itm => {
          if (Array.isArray(itm.job_roles)) {
            itm.hasPMRole = itm.job_roles.includes('pm') ? 'yes' : 'no'
            itm.hasCaptainRole = itm.job_roles.includes('captain') ? 'yes' : 'no'

            // // TEMP add captain role to all employees if not set
            // if (!itm.job_roles.length) {
            //   itm.hasCaptainRole = 'yes'
            //   itm.hasPMRole = 'yes'
            // }
          }
          return itm
        })
    },

    isJobPMCaptain() {
      if (this.isAdmin || this.isPMp) {
        return true
      }
      if (this.userID == this.job.Project_Manager || this.userID == this.job.Superintendent) {
        return true
      }
      return false
    },

    canEditDrawRequest() {
      const isBillingUser = this.billingEmployees.indexOf(this.userID) !== -1
      if (!this.loadedJob.Project_Manager || this.userID == this.loadedJob.Project_Manager || this.userID == this.loadedJob.Superintendent || isBillingUser) {
        return true
      }
      return false
    },

    allowCaptainEdit() {
      return this.editable && (this.isCaptain || !this.loadedJob.Superintendent) ? true : false
    },

    uploadData() {
      return {
        Job_Number: this.jobId,
        Image_Type: 'PDF',
        Image_Description: 'PO Image',
        Category: 'JOB',
        Drawer: 'PURCHASE ORDER'
      }
    },

    quoteLink() {
      if (this.quoteNumber) {
        const quoteIncr = this.extractQuoteIncr(this.quoteNumber)
        if (quoteIncr) {
          return `/app/all-quotes/${quoteIncr}`
        }
      }
      return ''
    },

    filteredSites() {
      return this.woSites.filter(itm => {
        return itm.Ship_To_Customer_Code == this.job.Customer_Code
      })
    },

    selectedSite() {
      if (this.job.WO_Site) {
        return this.woSites.find(itm => {
          return itm.Ship_To_ID == this.job.WO_Site
        })
      }
      return null
    },

    costCentersByDivision() {
      let costCenters = Array.isArray(this.emCostGroupsWithCostCenters) ? this.emCostGroupsWithCostCenters : []
      let selectedDivision = this.job.division_cost_group
      costCenters = costCenters.find(itm => {
        return itm.Cost_Group_Code === selectedDivision
      })
      costCenters = costCenters?.cost_centers
      return costCenters || []
    },

    filteredContacts() {
      const contacts = Array.isArray(this.customerContacts) ? this.customerContacts : []
      return contacts.filter(itm => itm.Account_Customer_Code__c === this.job.Customer_Code)
    }
  },

  methods: {
    selectJob() {
      this.$Modal({
        parent: this,
        name: 'job-wo-select', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        title: 'Select Job',
        component: () => import('components/Dashboard/JobWo/JobWOSearch.vue'),
        props: {
          type: 'Job'
        }
      })
    },

    listenSetStartFinishDates(startDate, finishDate) {
      this.job.Est_Start_Date = startDate
      this.job.Est_Complete_Date = finishDate
    },

    listenaddProjectUpdate(dateChangeLog) {
      this.job.date_change_log = JSON.parse(dateChangeLog)
    },

    async setData(refresh) {
      if (!this.jobId) {
        return
      }

      let job = this.jobAssemble.find(itm => {
        return parseInt(itm.Job_Number) == parseInt(this.jobId)
      })

      if (!job) {
        await this.getData(refresh)
        job = this.jobAssemble.find(itm => {
          return parseInt(itm.Job_Number) == parseInt(this.jobId)
        })
      }
      if (job) {
        // job.Price_Method_Code = 0
        // problem is that the field value is loading first, then being selected based on that default, then not updating according to recieving a value from job

        // assign if value exists as string
        for (var prop in job) {
          if (job.hasOwnProperty(prop)) {
            if (typeof job[prop] === 'string') {
              this.job[prop] = job[prop]
            }
          }
        }

        const jobUdfs = this.jobUdfs.find(itm => {
          return itm.Job_Number == this.jobId
        })
        if (jobUdfs) {
          job.sales_person = jobUdfs.sales_person
          this.quoteNumber = jobUdfs.quote_number
        }

        job.Original_Contract = job.Original_Contract || job.Total_Contract_Amount

        this.job = Object.assign({}, job)

        this.customerCode = job.Customer_Code

        this.loadedJob = Object.assign(this.loadedJob, this.job)

        // this relies on loadedJob data
        this.setOwner()
      } else {
        this.$snack.open('Problem loading Job', 'error')
      }
    },

    poFileAdded(file) {
      const fileName = file && file.name ? file.name : ''
      // strip extension
      const poNumber = fileName.replace(/\.[^/.]+$/, '')
      if (poNumber) {
        this.job.po_number = poNumber
      }
    },

    setOwner(contact) {
      if (!contact) {
        contact = appFuncs.contactNameToAppId(this.job.Owner_Name, this.customerContacts, true)
      }

      this.job.requested_by_id = contact ? contact.Id : null

      if (this.loadedJob.sales_person) {
        console.log('existing sales person', this.loadedJob.sales_person)
        return
      }
      // if sales lead is not set, set based on the heiarchy of account, site, contact owner from Salesforce
      let owner = null
      let type = null

      owner = contact ? contact.RealOwnerEmployeeCode__c : null
      type = owner ? 'contact' : null

      if (!owner) {
        const site = this.woSites.find(itm => {
          return itm.Ship_To_ID == this.job.WO_Site
        })
        owner = site ? site.RealOwnerEmployeeCode__c : null
        type = owner ? 'site' : null
      }

      if (!owner) {
        const customer = this.customers.find(itm => {
          return itm.Customer_Code == this.job.Customer_Code
        })
        owner = customer ? customer.RealOwnerEmployeeCode__c : null
        type = owner ? 'customer' : null
      }

      if (owner) {
        const validOwner = this.employees.find(itm => {
          return itm.Employee_Code == owner && itm.Employment_Status === 'A'
        })
        if (validOwner) {
          this.job.sales_person = owner
        }
      }
    },

    requestedByPreChange() {
      if (this.job.Owner_Name) {
        if (!confirm('You are changing the requested by. This is the customer name responsible for requesting the work.  Changing the name here will mean the customer name on invoices also changes'))
          return
      }
      this.allowRequestedByChange = true
    },

    getJobInfo() {
      return
      const params = {
        action: 'get_job_info',
        status: this.job.status,
        cc: this.job.Customer_Code
      }
      appFuncs
        .shHttp(params)
        .then(res => {
          if (res && res.status === 'success') {
            let data = Array.isArray(res.data) ? res.data : [res.data] // spectrum's stupid api returns obj if one result instead of arr for multiple
            var job = data.filter(itm => {
              if (itm && itm.Job_Number) {
                return parseInt(itm.Job_Number) == parseInt(this.jobId)
              }
            })
            // assign if value exists as string
            for (var prop in job[0]) {
              if (job[0].hasOwnProperty(prop)) {
                if (typeof job[0][prop] === 'string' && job[0][prop]) {
                  this.job[prop] = job[0][prop]
                }
              }
            }
          } else {
            this.$snack.open('Problem fetching some job info', 'error')
          }
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem adding price entries', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    openQuote(newTab) {
      if (!this.quoteLink) return
      if (newTab) {
        const frontend = this.$store.getters.urls.frontend
        window.open(`${frontend}#${this.quoteLink}`, '_blank', 'toolbar=no,location=no')
        return
      }
      this.$router.push({ path: this.quoteLink })
    },

    openQuotePdf() {
      // no price version, backend formulated using job number to ensure no price can be shown
      if (this.job.job_quote_url) {
        window.open(`${this.job.job_quote_url}`, '_blank', 'toolbar=no,location=no')
      }
    },

    hasUnsavedChanges() {
      const trackedItems = [
        'Customer_Code',
        'Job_Description',
        'Project_Manager',
        'Owner_Name',
        'Superintendent',
        'Estimator',
        'division_cost_group',
        'Cost_Center',
        'po_number',
        'quote_number',
        'sales_person',
        'sub_cost_center',
        'region'
      ]
      const job = JSON.parse(JSON.stringify(this.job))
      let loadedItems = this.filterObjectByKeys(this.loadedJob, trackedItems)
      loadedItems = this.convertObjectValsToString(loadedItems)
      let addedItems = this.filterObjectByKeys(job, trackedItems)
      addedItems = this.convertObjectValsToString(addedItems)

      const unsaved = diff(loadedItems, addedItems)
      if (!this.isObjEmpty(unsaved)) {
        this.hasUnsaved = true
        return true
      }
      this.hasUnsaved = false
      return false
    },

    async updateJob(inSequence) {
      return new Promise(async (resolve, reject) => {
        if (!this.jobId) {
          reject()
          return
        }

        if (this.isLocked && !this.allowRequestedByChange) {
          resolve()
          return
        }

        if (this.editable) {
          if (!this.validate()) {
            reject()
            return
          }
        }

        // set job location fields
        if (this.selectedSite) {
          this.job.Address_1 = this.selectedSite.site_address
          this.job.City = this.selectedSite.site_city
          this.job.State = this.selectedSite.site_province
          this.job.Zip_Code = this.selectedSite.Ship_To_Zip_Code
          this.job.Job_Site_Phone = this.selectedSite.site_phone_main
        }

        const data = Object.assign({}, this.job)
        delete data.Original_Contract

        const invoiceContacts = Array.isArray(data.invoice_contacts) ? data.invoice_contacts : []
        const jobContacts = Array.isArray(data.job_contacts) ? data.job_contacts : []

        data.invoice_contacts = invoiceContacts.filter(contactId => jobContacts.includes(contactId))

        if (!this.validateDates()) return

        const params = {
          action: 'update_job',
          job_number: this.jobId,
          data
        }

        this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating Job' })

        if (this.trackedPO === data.po_number) {
          delete data.po_number
          delete data.Contract_Number
        }

        await this.updateJobUDFs()

        const updateJob = appFuncs.shRequest(params)

        Promise.all([updateJob])
          .then(async () => {
            this.loadedJob = Object.assign(this.loadedJob, this.job)

            if (inSequence) {
              this.setJobPhases()

              resolve()
            } else {
              await this.getData(true)
              this.setData()
              resolve()
            }
          })
          .catch(res => {
            console.log(res)
            this.$snack.open(res && res.message ? res.message : 'Problem updating job.', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      })
    },

    validateDates() {
      // date format eg 2024-12-01 is non iso compliant and interpreted as ISO, so parseISO to use in local timezone
      const startDate = this.job.Est_Start_Date ? parseISO(this.job.Est_Start_Date) : null
      const startDateValid = startDate ? isAfter(startDate, new Date()) || isSameDay(startDate, new Date()) : false

      if ((!startDate || !startDateValid) && this.job.project_stage === 'not-started') {
        this.$snack.open('Start Date should not be in the past', 'warning')
        return false
      }

      const finishDate = this.job.Est_Complete_Date ? parseISO(this.job.Est_Complete_Date) : null
      const finishDateValid = finishDate ? isAfter(finishDate, new Date()) || isSameDay(finishDate, new Date()) : false
      if ((!startDate || !finishDateValid) && ['not-started', 'on-going', 'stagnant'].includes(this.job.project_stage)) {
        this.$snack.open('Finish Date should not be in the past', 'warning')
        return false
      }

      const finishDateAfterStartDate = finishDate ? isAfter(finishDate, startDate) || isSameDay(finishDate, startDate) : false
      if (!finishDateAfterStartDate && ['not-started', 'on-going', 'stagnant'].includes(this.job.project_stage)) {
        this.$snack.open('Finish Date should not be before start date', 'warning')
        return false
      }

      return true
    },

    updateJobUDFs() {
      return new Promise((resolve, reject) => {
        if (!this.jobId || this.isLocked) {
          resolve()
          return
        }
        const params = {
          action: 'update_job_udf_data',
          job_number: this.jobId,
          data: {
            sales_person: this.job.sales_person,
            quote_number: this.quoteNumber,
            billing_employee: this.job.billing_employee,
            progress_invoice: this.job.progress_invoice
          }
        }
        this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating Job Details' })
        appFuncs
          .shRequest(params)
          .then(data => {
            resolve()
          })
          .catch(res => {
            console.log(res)
            this.$snack.open(res.message || 'Problem updating job udfs', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      })
    },

    sortPhasesAbb(abbr) {
      let cats = this.phaseCategories
      for (var i = cats.length - 1; i >= 0; i--) {
        cats[i]
      }
    },

    getCustomerContacts(cc) {
      this.$store.dispatch('getCustomerContacts').then(() => {
        let contacts = this.customerContacts.filter(itm => {
          return itm.Account_Customer_Code__c == cc
        })
        this.contactsTableSettings.tableData = contacts
      })
    },

    formatPhoneLink(cc) {
      return appFuncs.formatPhoneLink(cc)
    },

    syncTimeEntries() {
      if (!confirm('Are you sure? Syncing entries will remove user ability to edit non-posted entries from MySheridan.')) {
        return
      }

      if (!this.jobId) {
        this.$snack.open('Problem syncing time cards please close and try again.', 'error')
      }

      const params = {
        action: 'sync_time_card_job_wo_entries',
        item_type: 'Job',
        item_number: this.jobId
      }

      this.$bus.$emit('setWaiting', {
        name: 'sync_time_card_job_wo_entries',
        message: 'Queuing Time Entries for Sync'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$bus.$emit('modalClose', 'job-sign-off-options')
          this.$snack.open('Time Card Entries are queued for sync, this could take some time depending on quantity.', 'error')
        })
        .catch(res => {
          this.$snack.open(res.message, 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'sync_time_card_job_wo_entries')
        })
    },

    editContact(editId) {
      this.$Modal({
        parent: this,
        name: 'customer-contact-add-edit', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        title: '',
        component: CustomerContactsAddEdit,
        props: {
          editId
        }
      })
    },

    setJobPhases() {
      this.$Modal({
        parent: this,
        name: 'job-phases-numbers-details', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        title: 'Set Job Phases',
        component: () => import('components/Dashboard/JobWo/jobPhasesNumbersDetails.vue'),
        props: {
          job: this.job,
          jobId: parseInt(this.jobId),
          isLocked: this.isLocked,
          enterFromJobAdd: true
        }
      })
    },

    selectJobQuoteNumber() {
      if (this.loadedJob.quote_number && this.jobId) return // only allow if new job
      this.$Modal({
        parent: this,
        name: 'job-quote-select', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        title: 'Job Quote Select',
        component: () => import('components/Dashboard/Quotes/JobQuoteSelect.vue'),
        props: {
          jobId: parseInt(this.jobId),
          isLocked: this.isLocked,
          job: this.job,
          addSetQuoteNumber: this.addSetQuoteNumber
        }
      })
    },

    formatNumber(num, dollar) {
      return appFuncs.formatNumber(num, dollar)
    },

    getJobPMDetails() {
      var data = {
        action: 'get_job_pm_details',
        job_number: this.jobId
      }

      this.$bus.$emit('setWaiting', {
        name: data.action,
        message: 'Fetching Job PM Details'
      })

      appFuncs.ajax_request(
        this.$store.getters.sherAuth,
        data,
        result => {
          this.$bus.$emit('stopWaiting', data.action)
          if (result.status === 'success') {
            let jobPMDetails = result.data.find(itm => {
              return itm.Job_Number == this.jobId
            })
            // keep as object if undefined
            if (jobPMDetails) {
              this.jobPMDetails = jobPMDetails
            } else {
              this.$snack.open('There has been a problem retrieving Job PM data', 'error')
            }
          } else {
            this.$snack.open(result.message, 'error')
          }
        },
        1,
        this.$store.getters.urls.mssql + 'ajax.php'
      )
    },

    getJobSignOff() {
      const data = {
        action: 'get_job_sign_off_template_code',
        job_number: this.jobId
      }
      this.$bus.$emit('setWaiting', {
        name: data.action,
        message: 'Getting Job Sign Off code'
      })
      appFuncs
        .shRequest(data)
        .then(signOffUrl => {
          this.jobSignOff = signOffUrl
        })
        .catch(res => {
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', data.action)
        })
    },

    unLockJob() {
      let params = {
        action: 'job_status_update',
        data: {
          job_number: this.jobId,
          status: '2'
        }
      }

      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Unlocking Job'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$snack.open('Job has been unlocked')
          this.getData('refresh').then(() => {
            this.setData()

            if (this.$refs.jobwipdetails) {
              this.$refs.jobwipdetails.loadData(this.jobId)
            }
          })
        })
        .catch(res => {
          console.log(res)
          this.$snack.open(res.message || 'Problem unlocking job, please refresh to try again', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    checkSignoffRequirements() {
      if (!this.jobId) return
      this.validateForSignoff(this.jobId).then(res => {
        if (res.status !== 'success') {
          this.$snack.open('Problem checking Signoff requirements', 'error')
          return
        }
        if (res.missingRequirements) {
          this.missingSignoffRequirements = res.missingRequirements
        } else {
          this.missingSignoffRequirements = []
        }
      })
    },

    addTimeEntry() {
      this.$bus.$emit('modalOpen', {
        title: 'Add Time Entry',
        classes: 'gray-bg',
        name: 'add-time-entry',
        size: 'modal-l',
        component: 'add-time-entry',
        componentData: {
          department: 'JOB',
          job_number: this.jobId,
          inlineSelectItems: false
        }
      })
    },

    getGlobalOptions(group) {
      return new Promise((resolve, reject) => {
        var params = {
          action: 'get_global_options',
          group
        }
        this.$bus.$emit('setWaiting', {
          name: `get_global_options ${group}`,
          message: 'Getting Options'
        })
        appFuncs
          .shRequest(params)
          .then(data => {
            this.globalOptions[group] = data
            this.globalOptions = Object.assign({}, this.globalOptions)
            resolve()
          })
          .catch(err => {
            this.$snack.open(err.message || 'Problem getting option', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', `get_global_options ${group}`)
          })
      })
    },

    getQuote() {
      return new Promise((resolve, reject) => {
        var params = {
          action: 'get_quotes',
          quote_number: this.quoteNumber
        }
        this.$bus.$emit('setWaiting', {
          name: `get_quote`,
          message: 'Getting Quote'
        })
        appFuncs
          .shRequest(params)
          .then(data => {
            this.quote = data
            resolve()
          })
          .catch(err => {
            this.$snack.open(err.message || 'Problem getting quote', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', `get_quote`)
          })
      })
    },

    listenJobDataUpdated() {
      this.getData('refresh').then(() => {
        this.setData()
      })
    },

    validate() {
      const required = [{ name: 'Job_Description', value: 'Job Name' }]

      if (!this.validateDates()) return

      required.push(
        { name: 'Job_Description', value: 'Job Name' },
        { name: 'Customer_Code', value: 'Customer' },
        { name: 'WO_Site', value: 'Site' },
        { name: 'Price_Method_Code', value: 'Price Type' },
        { name: 'Owner_Name', value: 'Requested By' },
        { name: 'Create_Date', value: 'Create Date' },
        { name: 'Est_Start_Date', value: 'Expected Start Date' },
        { name: 'Est_Complete_Date', value: 'Expected Finished Date' },
        { name: 'Project_Manager', value: 'Project Lead' },
        // { name: 'sales_person', value: 'Sales Person' },
        { name: 'Superintendent', value: 'Captain' },
        { name: 'Estimator', value: 'Estimator' }
      )

      // require division(AUTOMATION) for new jobs only
      if (!this.job.division_cost_group) {
        required.push({ name: 'division_cost_group', value: 'Division' })
      }

      // require department(cost center) for new jobs only
      if (!this.job.Cost_Center) {
        required.push({ name: 'Cost_Center', value: 'Department' })
      }

      // only for below validation
      this.job.quote_number = this.quoteNumber
      if (!this.jobId && this.job.Price_Method_Code === 'F') {
        if (!this.isAdmin || !confirm('You are using fixed price type without a quote, are you sure?')) {
          required.push({ name: 'quote_number', value: 'Quote with Fixed Price Type' })
        }
      }

      for (let i = 0; i < required.length; i++) {
        if (!this.job[required[i].name]) {
          this.$snack.open(`Missing ${required[i].value}`, 'warning')
          return
        }
      }
      this.job.Contract_Number = this.job.po_number

      return true
    },

    editRequiredDate() {
      this.$Modal({
        parent: this,
        name: 'SetEstimatedStartDate',
        size: 'lg',
        title: 'Project Dates',
        component: () => import('components/Dashboard/JobWo/SetEstimatedStartDate.vue'),
        props: {
          obj: this.job
        }
      })
    },

    async addJob() {
      if (!this.isPM || !this.validate()) return
      if (await this.isOnHold(this.job.Customer_Code)) return

      // set job location fields
      if (this.selectedSite) {
        this.job.Address_1 = this.selectedSite.site_address
        this.job.City = this.selectedSite.site_city
        this.job.State = this.selectedSite.site_province
        this.job.Zip_Code = this.selectedSite.Ship_To_Zip_Code
        this.job.Job_Site_Phone = this.selectedSite.site_phone_main
      }

      // add the data for New JobDescriptionNote in params
      this.job.Note_Table = 'JobDescriptionNotes'
      this.job.Author_Id = this.userAuthedData.eid
      this.job.Author_Name = this.userAuthedData.fname + ' ' + this.userAuthedData.lname
      this.job.Note_Text = `${this.job.Job_Description} as per quote ${this.quoteNumber}, sent on ${this.quoteData.sent_date}`
      if (this.job.po_number) {
        this.job.Note_Text += `, Customer PO# ${this.job.po_number}`
      }
      this.job.Item_Id = this.jodId

      const params = { action: 'add_job', data: this.job }

      const jobAmount = this.job.Original_Contract
      if (this.job.progress_invoice !== 'yes' && jobAmount && parseInt(jobAmount) >= 100000) {
        if (confirm('This job meets the normal criteria for progress billing - should the "Draw Request - Progress Invoice" option be selected?')) {
          this.job.progress_invoice = 'yes'
        }
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Adding Job' })
      appFuncs
        .shRequest(params)
        .then(async data => {
          this.$snack.open('Job Added')

          this.jobId = data.Job_Number

          // happens below in setData
          // await this.updateJobUDFs()

          // just a delay
          await new Promise(resolve => setTimeout(resolve, 500))

          try {
            // this.$refs.poUploadComponent.processQueue();
            await this.$refs.jobPOUpload.uploadFile()
          } catch (err) {
            console.log('child process queue failed', err)
            console.log(this.$refs)
          }

          // mainly for updating owner name
          try {
            // will also update job and job udf data in app
            await this.updateJob('inSequence')

            this.loadedJob = Object.assign(this.loadedJob, this.job)
          } catch (err) {
            console.log(err)
          }
        })
        .catch(res => {
          console.log(res)
          this.$snack.open(res.message || 'Problem adding job', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    listenJobQuoteSelect(obj) {
      const quoteNumber = obj && obj.quote_number ? obj.quote_number : null
      if (quoteNumber) {
        this.quoteNumber = obj.quote_number
        this.quoteData = obj
        if (!this.jobId) {
          this.setValsFromQuote(obj)
        }
      }
    },
    setValsFromQuote(obj) {
      // this.job.Price_Method_Code = obj.payment_price_type || 0 // dont set automatically MN
      this.job.Original_Contract = obj.selling_price || 0
      this.job.Customer_Code = obj.customer_code || ''
      this.job.WO_Site = obj.site_id || ''
      // trim description to 25 chars
      this.job.Job_Description = obj.description ? sanitizeHtml(obj.description).substring(0, 25) : ''
      this.job.Owner_Name = obj.contact || ''
      // this.job.Project_Manager = obj.project_manager || ''
      this.job.Superintendent = '' // to be set by the user on the quote dec 23
      this.job.Estimator = obj.captain || ''
      this.job.sales_person = obj.sales_lead_id || ''
      this.job.job_quote_url = obj.quote_pdf || ''
      this.job.region = obj.region || ''
      this.job.deposit_percent = obj.deposit_percent || ''

      this.job.Est_Complete_Date = obj.date_project_complete || null
      this.job.Est_Start_Date = obj.date_project_start || null

      setTimeout(() => {
        this.job.Cost_Center = obj.cost_center || ''
        this.job.sub_cost_center = obj.sub_cost_center || ''
      }, 1000)

      this.setFromQuote = true
    },

    getQuoteDeptJobCostCenter(dept, amt) {
      switch (dept) {
        case 'Contracting':
        case 'Contracting - Labour Supply':
          return amt >= '300000' ? '1000' : '6000'
        case 'Automation':
        case 'Control Panels':
          return '2000'
        case 'Water Works':
          return '8000'
        case 'GC - Industrial':
        case 'GC - Base Building':
        case 'Data Centers':
          return '1000'
        case 'Technical Services':
          return '5000'
        default:
          return null
      }
    },

    async setGetJob() {
      try {
        await this.getData()
      } catch (err) {
        console.log(err)
        return
      }
      this.setData()
    },

    async getData(refresh) {
      return new Promise((resolve, reject) => {
        this.$bus.$emit('setWaiting', { name: 'getdata', message: 'Getting Data' })
        const contacts = this.$store.dispatch('getCustomerContacts')
        const sites = this.$store.dispatch('getWOSites')
        const employees = this.$store.dispatch('getEmployees')
        const customers = this.$store.dispatch('getCustomers')
        let promises = [contacts, sites, employees, customers]

        if (this.jobId) {
          const jobAssemble = this.$store.dispatch('getJobAssemble', { refresh: refresh, job_number: this.jobId })
          promises.push(jobAssemble)
        }
        Promise.all(promises)
          .then(() => {
            resolve()
          })
          .catch(res => {
            console.log(res)
            this.$snack.open(res.message || 'Problem getting data', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'getdata')
          })
      })
    },

    listenSelectJob(jobNumber) {
      this.$router.push({ path: `/app/job-details/${jobNumber}` })
      this.jobId = jobNumber
      this.getData()
      this.setData()
    },

    openCustomerWarnings() {
      this.$Modal({
        parent: this,
        name: 'ShowCustomerWarnings', // used for closing specific modal programmatically
        size: 'sm', // sm, md, lg, xl
        hideClose: false,
        component: () => import('src/components/UIComponents/ShowCustomerWarnings.vue'),
        contentClasses: 'smoke-bg',
        props: {
          customerCode: this.job.Customer_Code
        }
      })
    },

    requestPO() {
      this.$Modal({
        parent: this,
        name: 'PORequestEmail', // used for closing specific modal programmatically
        title: 'Request PO',
        size: 'md', // sm, md, lg, xl
        component: () => import('src/components/Dashboard/JobWo/PORequestEmail.vue'),
        props: {
          jobNumber: this.jobId || ''
        }
      })
    },
    get_customer_notes() {
      if (this.job.Customer_Code != '') {
        let params = {
          action: 'get_item_notes',
          table: 'CustomerWarnings',
          item_id: this.job.Customer_Code
        }

        this.show_warnings = false

        this.wait = { message: 'Getting notes' }
        appFuncs.ajax_request(store.getters.sherAuth, params, result => {
          this.wait = { message: '' }
          if (result.status == 'success') {
            let allNotes = result.data
            if (allNotes.length > 0) {
              this.show_warnings = true
            }
          } else {
            this.$snack.open(result.message || 'Problem getting notes', 'error')
          }
        })
      }
    },

    addJobContacts(arr) {
      // Ensure invoice_contacts is an array
      if (!Array.isArray(this.job.invoice_contacts)) {
        this.job.invoice_contacts = []
      }

      // Check if the contact already exists in the array
      const newContact = arr[0]
      const exists = this.job.invoice_contacts.some(contact => contact.Id === newContact)

      // Add the contact if it doesn't exist
      if (!exists) {
        this.job.invoice_contacts.push(newContact)
      }
    },

    async isOnHold(code) {
      const selectedCustomer = this.customers.find(e => e.Customer_Code === code)
      if (selectedCustomer?.on_hold === 'yes' && !this.isAdmin) {
        if (!(await showConfirmDialog('Warning: this account is on-hold. Please ask your supervisor for support', true))) return true
        return true
      }
      return false
    },

    async listenJobStatusUpdate() {
      this.setData('refresh')
    }
  },

  async mounted() {
    this.get_customer_notes()
    this.jobId = this.$route.params.id

    // if (!this.isPM && (!this.isEditorf || this.myJobIds.map(Number).includes(Number(this.jobId)))) return // dont load anything if not a PM and not an editor or not a job of the user

    const priceTypeOptions = this.getGlobalOptions('payment_price_type')
    const depositAmountOptions = this.getGlobalOptions('deposit_amount')
    const getData = this.getData('refresh') // refresh needed here when navigating back to new job after it just being added
    const emCostGroups = this.$store.dispatch('getEmCostGroups')
    const emCostGroupsWithCostCenters = this.$store.dispatch('getEmCostGroupsWithCostCenters', { type: 'job' })

    await Promise.all([priceTypeOptions, depositAmountOptions, getData, emCostGroups, emCostGroupsWithCostCenters])

    this.setData()
    this.checkSignoffRequirements()
    this.$bus.$on('checkJobSignoffRequirements', () => {
      this.checkSignoffRequirements()
    })

    // get contacts table to update on save
    this.$bus.$on('bhUpdate', obj => {
      this.getCustomerContacts(this.customerCode)
    })

    if (this.jobId) {
      this.getJobPhases('silent')
    }

    this.$bus.$on('jobDataUpdated', this.listenJobDataUpdated)

    this.unsavedInterval = setInterval(() => {
      this.hasUnsavedChanges()
    }, 2000)

    this.$bus.$on('jobQuoteSelect', this.listenJobQuoteSelect)

    if (this.addSetQuoteNumber) {
      this.selectJobQuoteNumber()
    }

    this.$bus.$on('selectSetJob', this.listenSelectJob)
    this.$bus.$on('updateJobStage', this.listenJobStatusUpdate)

    this.getJobSignOff()

    this.$bus.$on('setStartFinishDates', this.listenSetStartFinishDates)
    this.$bus.$on('addProjectUpdate', this.listenaddProjectUpdate)
  },

  watch: {
    tab(newTab, oldTab) {
      if (this.tab === 'Contacts') {
        if (this.customerCode) {
          this.getCustomerContacts(this.customerCode)
        } else {
          this.customerContacts = 'No customer code found for contact, please refresh to try again.'
        }
      }
      if (this.tab === 'PM') {
        //this.getJobPMDetails();
      }
      if (newTab !== 'Main' && oldTab === 'Main') {
        if (this.hasUnsavedChanges()) {
          if (!confirm('You have unsaved changes, are you sure you want to leave this page?')) {
            this.tab = oldTab
            return
          }
        }
      }
    },

    'job.Job_Description': function (newVal, oldVal) {
      if (newVal.length > 25) {
        this.job.Job_Description = oldVal
      }
    },

    'job.Customer_Code': {
      async handler(newVal, oldVal) {
        if (!this.job.id && (await this.isOnHold(newVal))) {
          this.job.Customer_Code = oldVal
          return
        }
        this.get_customer_notes()
      },
      immediate: false
    },

    customerCode() {
      this.getCustomerContacts(this.customerCode)
    },

    'job.Original_Contract': function (newVal, oldVal) {
      if (newVal && parseInt(newVal) >= 100000) {
        this.job.progress_invoice = 'yes'
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.unsavedInterval)
    this.$bus.$off('jobDataUpdated', this.listenJobDataUpdated)
    this.$bus.$off('jobQuoteSelect', this.listenJobQuoteSelect)
    this.$bus.$off('selectSetJob', this.listenSelectJob)
    this.$bus.$off('updateJobStage', this.listenJobStatusUpdate)
    this.$bus.$off('setStartFinishDates', this.listenSetStartFinishDates)
    this.$bus.$off('addProjectUpdate', this.listenaddProjectUpdate)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.file-uploader-container {
  margin-bottom: 20px;
}

hr {
  margin: 20px 0;
}

.content {
  padding: 30px !important;
}

.tab {
  &.contacts h5 {
    margin: 0 0 10px;
    font-size: 18px;
  }
}

p {
  min-height: 14px;
}

.phases-tab {
  ul {
    padding: 0;
  }

  li {
    display: block;
    margin: 0;
    padding: 0;
  }
}

.detail-item {
  .job-number-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 0 10px;

    h4 {
      margin: 0;
      font-weight: bolder;
    }

    i {
      font-size: 12px;
    }
  }

  .btn {
    margin: 0 0 10px;
  }

  .detail-item--edit {
    position: relative;
  }
}

input.invalid {
  background: $input-red;
}
</style>
